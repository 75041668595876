import React from "react";
import { QuestionsPanel } from "./QuestionsPanel";
import styles from "./QuestionnaireBuilder.module.css";
import { createSchemaField } from "@formily/react";
import { FormItem, Input, Select } from "@formily/antd-v5";
import { QuestionsMetaDnd } from "../../types";
import {
  WppIconCross,
  WppListItem,
  WppInput,
  WppSelect,
  WppTextareaInput,
} from "@platform-ui-kit/components-library-react";

type BuilderPanelProps = {
  handleMouseEnter: (e) => void;
  handleQuestionDrop: (type: string) => void;
  handleQuestionDelete: (uuid: string) => void;
  handleClearField: (
    uuid: string,
    index: number,
    field: string,
    itemIndex: number
  ) => void;
  handleChange: (
    uuid: string,
    index: number,
    field: string,
    itemIndex: number,
    value: string
  ) => void;
  questions: Array<QuestionsMetaDnd>;
  form: any;
};
export const BuilderPanel: React.FC<BuilderPanelProps> = (props) => {
  const SchemaField = createSchemaField({
    components: {
      Input,
      Select,
      WppInput,
      WppSelect,
      WppTextareaInput,
    },
  });

  return (
    <div className={styles["workspace-panel-wrapper"]}>
      <div className={styles["workspace-panel"]}>
        <SchemaField>
          <SchemaField.String
            name="survey_name"
            // x-component-props={{
            //   bordered: false,
            //   style: { fontSize: "30px" },
            //   allowClear: true,
            // }}
            default="Please Enter Survey Name"
            required={true}
            x-component="WppInput"
            x-decorator={FormItem}
            x-component-props={{
              name: "survey_name",
              onWppChange: (e) => {
                props.form.setValuesIn("survey_name", e.target.value);
              },
              defaultValue: "Please Enter Survey Name",
              className: styles["survey-header-input-title"],
              children: (
                <WppIconCross
                  slot="icon-end"
                  aria-label="Clear icon"
                  onClick={() => {
                    //props.handleClearField(answerTextId, index);
                    props.form.setValuesIn("survey_name", "");
                  }}
                />
              ),
            }}
          />
          <SchemaField.String
            name="description"
            x-component="WppTextareaInput"
            default="Please Enter Description..."
            x-component-props={{
              name: "description",
              defaultValue: "Please Enter Description...",
              className: styles["survey-header-input-desc"],
              rows: 2,
              onWppChange: (e) => {
                props.form.setValuesIn("description", e.target.value);
              },
            }}
            x-decorator={FormItem}
          />
          <SchemaField.Number
            name="methodology"
            title="Methodology"
            required={true}
            x-decorator={FormItem}
            x-component="WppSelect"
            x-component-props={{
              name: "methodology",
              title: "Methodology",
              style: {
                width: "60%",
              },
              onWppChange: (e) => {
                props.form.setValuesIn("methodology", e.detail.value);
              },
              value: props.form.values["methodology"],
              children: (
                <>
                  <WppListItem value="ONLINE">
                    <p slot="label">Online</p>
                  </WppListItem>
                  <WppListItem value="OFFLINE">
                    <p slot="label">Offline</p>
                  </WppListItem>
                </>
              ),
            }}
          />
          {/* <SchemaField.Number
            name="methodology"
            title="Methodology"
            required={true}
            x-decorator={FormItem}
            x-component="Select"
            enum={[
              { label: "Online", value: "ONLINE" },
              { label: "Offline", value: "OFFLINE" },
            ]}
            x-component-props={{
              allowClear: true,
              style: {
                width: "60%",
              },
            }}
          /> */}
          {/* <SchemaField.Number
            name="type"
            title="Type"
            required={true}
            x-decorator={FormItem}
            x-component="Select"
            enum={[
              { label: "Core", value: "CORE" },
              { label: "Teenager", value: "TEENAGER" },
              { label: "Luxury", value: "LUXURY" },
              { label: "Other", value: "OTHER" },
            ]}
            x-component-props={{
              allowClear: true,
              style: {
                width: "60%",
              },
            }}
          /> */}
          <SchemaField.Number
            name="type"
            title="Type"
            required={true}
            x-decorator={FormItem}
            x-component="WppSelect"
            x-component-props={{
              name: "type",
              title: "Type",
              style: {
                width: "60%",
              },
              onWppChange: (e) => {
                props.form.setValuesIn("type", e.detail.value);
              },
              value: props.form.values["type"],
              children: (
                <>
                  <WppListItem value="CORE">
                    <p slot="label">Core</p>
                  </WppListItem>
                  <WppListItem value="TEENAGER">
                    <p slot="label">Teenager</p>
                  </WppListItem>
                  <WppListItem value="LUXURY">
                    <p slot="label">Luxury</p>
                  </WppListItem>
                  <WppListItem value="OTHER">
                    <p slot="label">Other</p>
                  </WppListItem>
                </>
              ),
            }}
          />
        </SchemaField>
      </div>
      <QuestionsPanel
        handleMouseEnter={props.handleMouseEnter}
        handleQuestionDrop={props.handleQuestionDrop}
        handleQuestionDelete={props.handleQuestionDelete}
        handleClearField={props.handleClearField}
        handleChange={props.handleChange}
        questions={props.questions}
        form={props.form}
      ></QuestionsPanel>
    </div>
  );
};
