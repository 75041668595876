import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  WppIconAdd,
  WppIconDone,
  WppIconPlusCircle,
  WppIconTrash,
  WppInput,
  WppTypography,
} from "@platform-ui-kit/components-library-react";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Button } from "antd";

const AnswerGridLayout = ({ tableData, type, setData }) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const displayData = {
    ans_code:
      tableData.length == 0 ? 1 : tableData[tableData.length - 1].ans_code + 1,
    ans_text: "",
    ans_filter: "",
    ans_type: type,
    ans_sequence:
      tableData.length == 0 ? 1 : tableData[tableData.length - 1].ans_code + 1,
  };

  const columnDefs: ColDef[] = [
    {
      field: "Code",
      cellRenderer: ({
        data,
        updateColumnData,
      }: {
        data;
        updateColumnData;
      }) => {
        const messageType = data.meta?.error ? "error" : undefined;

        return (
          <div className="filterCell">
            <WppInput
              value={data.ans_code}
              size="s"
              messageType={messageType}
              onWppBlur={(e) =>
                updateColumnData(data.ans_sequence, "ans_code", e.target.value)
              }
              style={{ marginTop: "6px" }}
            />
          </div>
        );
      },
    },
    {
      field: "Text",
      cellRenderer: memo(
        ({ data, updateColumnData }: { data; updateColumnData }) => {
          const messageType = data.meta?.error ? "error" : undefined;

          return (
            <div className="textCell">
              <WppInput
                value={data.ans_text}
                size="s"
                messageType={messageType}
                onWppBlur={(e) =>
                  updateColumnData(
                    data.ans_sequence,
                    "ans_text",
                    e.target.value
                  )
                }
                required
                style={{ marginTop: "6px" }}
              />
            </div>
          );
        }
      ),
    },
    {
      field: "Filter",
      cellRenderer: memo(
        ({ data, updateColumnData }: { data; updateColumnData }) => {
          const messageType = data.meta?.error ? "error" : undefined;

          return (
            <div className="filterCell">
              <WppInput
                value={data.ans_filter}
                size="s"
                messageType={messageType}
                onWppBlur={(e) =>
                  updateColumnData(
                    data.ans_sequence,
                    "ans_filter",
                    e.target.value
                  )
                }
                style={{ marginTop: "6px" }}
              />
            </div>
          );
        }
      ),
    },
    {
      field: "Actions",
      width: 100,
      cellRenderer: ({ data }: { data: any }) => (
        <Button
          icon={<WppIconTrash />}
          className={"wpp-spacing-8-right"}
          onClick={() => {
            let newData = [...tableData];
            newData = newData.filter(
              (v) => v.ans_sequence !== data.ans_sequence
            );
            setData(newData);
          }}
          style={{ marginTop: "6px" }}
        ></Button>
      ),
    },
  ];

  return (
    <div style={{ width: "800px", marginBottom: 10 }} className="ag-theme-wpp">
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: false,
          cellRenderer: ({ value }: ColDef["cellRenderer"]) => {
            return <WppTypography type="s-body">{value}</WppTypography>;
          },
          cellRendererParams: {
            updateColumnData: useCallback(
              (ans_sequence, key, value) => {
                let newData = [...tableData];
                newData = newData.map((v) => {
                  if (v.ans_sequence === ans_sequence) {
                    v[key] = value;
                  }
                  return v;
                });

                setData(newData);
              },
              [tableData]
            ),
          },
        }}
        rowClassRules={{
          "with-error": (data) => (data.data ? !!data.data.meta?.error : false),
        }}
        domLayout="autoHeight"
      />
      <Button
        icon={<WppIconPlusCircle />}
        onClick={() => {
          const newData = [...tableData];
          newData.push(displayData);
          setData([...newData]);
        }}
      ></Button>
    </div>
  );
};

export default AnswerGridLayout;
