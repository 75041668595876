import {AppConfig, getIngestionGCSPrefix} from "../../../AppConfig";

export const MiscDagsConstants = {
    variableOrderWikiLink: "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337951531286/AO+variable+ordering+pipeline",
    variableOrderUploadUrl:
        getIngestionGCSPrefix(AppConfig.environment, "variable_order"),
    triggerVariableOrderDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_import_export_output_mapping",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_import_export_output_mapping/graph`,
        inputFields: [
            {
                fieldId: "version",
                fieldLabel: "Version: ",
                fieldType: "string",
                tooltip: "2X (for 2.x data), 3X, 4X etc.."
            },
            {
                fieldId: "report_type",
                fieldLabel: "Report Type",
                fieldType: "string",
                tooltip: "e.g. OFFLINE, ONLINE"
            },
        ]
    },
}
