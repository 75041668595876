import {
    WppCheckbox,
    WppTypography,
} from "@platform-ui-kit/components-library-react";
import React from "react";

type Props = {
    checked: boolean;
    onChange: (value: boolean) => void;
    title: string;
    context: string[];
    checkboxText: string;
    links: { label: string; link: string; context: string }[];
};

export const InstructionStep = (props: Props) => {
    const handleCheckboxChange = (event) => {
        props.onChange(event.detail.checked);
    };

    return (
        <WppTypography type="l-body">
            <div style={{marginBottom: `1rem`}}>
                <h3>{props.title}</h3>
                <ul>
                    {props.context.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
            <div style={{marginBottom: `1rem`}}>
                <ul>
                    {props.links && props.links.map((item, index) => (
                        <li key={index}>
                            {item.context}: <a href={item.link} target="_blank">{item.label}</a>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <WppCheckbox
                    required={true}
                    checked={props.checked}
                    indeterminate={false}
                    labelConfig={{
                        text: props.checkboxText
                    }}
                    onWppChange={handleCheckboxChange}
                />
            </div>
        </WppTypography>
    );
};
