import React, { useState } from "react";
import {
    WppInput,
    WppSelect,
    WppTypography,
    WppButton,
    WppListItem
} from "@platform-ui-kit/components-library-react";
import { AudienceOriginRequest, EndPoints } from "../../api/audience-origin";
import { AppConfig } from "../../AppConfig";
import { useCookies } from 'react-cookie';
import axios from 'axios';

interface InputChangeEventDetail {
    value: string;
}

interface WppInputCustomEvent<T> extends Event {
    detail: T;
}

interface SelectChangeEventDetail {
    value: string;
}

interface WppSelectCustomEvent<T> extends Event {
    detail: T;
}

type Props = {
    testSuite: string;
    projectId: string;
    onDagSuccess: () => void;
};

export const TriggerDag: React.FC<Props> = (props: Props) => {
    const [surveys, setSurveys] = useState("");
    const [useAscription, setUseAscription] = useState<string | null>(null);
    const [status, setStatus] = useState("-");
    const [dagId, setDagId] = useState("");
    const [dagRunId, setDagRunId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [cookies] = useCookies(['access_token_cookie', 'csrf_access_token']);

    const handleInputChange = async () => {
        if (!surveys || !props.testSuite || !props.projectId || useAscription === null) {
            setErrorMessage("Please fill out all required fields.");
            setHasError(true);
            return;
        }

        const datconfig = {
            surveys,
            test_suite: props.testSuite,
            project_id: props.projectId,
            use_ascription: useAscription
        };

        const dagName = "ao_qa";

        try {
            setHasError(false);
            setIsLoading(true);

            // First API call to trigger DAG
            const triggerStatus = await AudienceOriginRequest.post(
                `${EndPoints.RawDataTriggerDAG}/${dagName}`,
                datconfig,
                { headers: { "Content-Type": "application/json" } }
            );



            setStatus(triggerStatus.data.state);
            setDagId(triggerStatus.data.dagId);
            setDagRunId(triggerStatus.data.dagRunId);

            // Second API call to create a project
           // const csrfToken = cookies.csrf_access_token; // Extract CSRF token from cookies
             //const accessToken = cookies.access_token_cookie; // Extract Access token from cookies
            //const accessToken = localStorage.getItem('access_token');
            //const projectConfig = {
              //  headers: {
                //    "Content-Type": "application/json",
                  //  "X-CSRF-Token": csrfToken || '',
                  //  "Authorization": `Bearer ${accessToken}` // Assuming the access token is a Bearer token
                //},
                //withCredentials: true // Ensure cookies are sent with the request
          // };


            // Make sure the API endpoint is correctly formatted
           //await axios.post(
             //   `${AppConfig.alluerDockerApiUrl}/projects`,
               // { "id": props.projectId },
                //projectConfig
            //);

            props.onDagSuccess();
        } catch (error) {
            setErrorMessage(error.response?.data?.message ?? error.message);
            setHasError(true);
        } finally {
            setIsLoading(false);
        }

    
    };

    const handleAscriptionChange = (event: WppSelectCustomEvent<SelectChangeEventDetail>) => {
        const value = event.detail.value;
        setUseAscription(value === "true" ? "true" : "false");
    };

    const handleChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
        setSurveys(event.detail.value);
    };

    const handleButtonClick = () => {
        handleInputChange();
    };

    return (
        <div>
            {status === "-" ? (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <WppTypography>Survey name: </WppTypography>
                            <div style={{ display: 'flex', marginLeft: '10rem' }}>
                                <WppInput value={surveys} onWppChange={handleChange} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <label>Project_id:</label>
                            <div style={{ display: 'flex', marginLeft: '11.2rem' }}>
                                <WppInput value={props.projectId} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <label>Test_suite: </label>
                            <div style={{ display: 'flex', marginLeft: '11.2rem' }}>
                                <WppInput value={props.testSuite} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <label>Use_ascription: </label>
                            <div style={{ display: 'flex', marginLeft: '9rem' }}>
                                <WppSelect
                                    onWppChange={handleAscriptionChange}
                                    placeholder="use_ascription"
                                    value={useAscription === null ? "" : useAscription}
                                >
                                    <WppListItem value="true">
                                        <p slot="label">True</p>
                                    </WppListItem>
                                    <WppListItem value="false">
                                        <p slot="label">False</p>
                                    </WppListItem>
                                </WppSelect>
                            </div>
                        </div>
                        <WppButton
                            onClick={handleButtonClick}
                            style={{ marginTop: "1rem", marginLeft: "10rem" }}
                        >
                            {isLoading ? "Triggering DAG..." : "Trigger DAG"}
                        </WppButton>
                        {hasError && (
                            <p style={{ color: "red", marginTop: "0.5rem", marginLeft: "10rem" }}>
                                {errorMessage}
                            </p>
                        )}
                    </div>
                </div>
            ) : (
                <WppTypography type="l-body">
                    <ul>
                        <li>Status of DAG: {status}</li>
                        <li>DAG Id: {dagId}</li>
                        <li>DAG Run Id: {dagRunId}</li>
                        <li>
                            Link for airflow UI where user can check logs
                            <ul>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={AppConfig.A0QAurl}
                                    >
                                        Click Here
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </WppTypography>
            )}
        </div>
    );
};
