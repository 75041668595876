import React, { useEffect, useState } from 'react';
import { WppInput } from '@platform-ui-kit/components-library-react';
import { AppConfig } from '../../AppConfig';
import { AxiosRequestConfig } from 'axios';
import axios from '../../api/axios-allure-docker';
import { useCookies } from 'react-cookie';
import { useDebounce } from 'use-debounce'; // Import useDebounce from use-debounce
import { error } from 'console';

// Define the custom event type based on WppInput
interface InputChangeEventDetail {
    value: string;
}

interface WppInputCustomEvent<T> extends Event {
    detail: T;
}

type Props = {
    value: string;
    folderValidation:string;
    setfolderValidation:any;
    handleInputChange: (value: string) => void;
    errorMessage?: string;
};

export const ResultFolder: React.FC<Props> = ({ value, handleInputChange,folderValidation,setfolderValidation, errorMessage }: Props) => {
    const [apiError, setApiError] = useState<string | null>(null);
    const [cookies] = useCookies(['csrf_access_token']); // Use react-cookie to manage cookies

    // Use debounce to delay the API call
    const [debouncedValue] = useDebounce(value, 500); // Debounce delay in milliseconds

    // Adjust the handleChange to work with WppInputCustomEvent
    const handleChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
        handleInputChange(event.detail.value);
    };

    

    useEffect(() => {
        if (debouncedValue.trim() === '') {
            setApiError('please enter  folder name');
            return;
        }
        const checkProjectExistence = async () => {
            try {
                // Extract CSRF token from cookies
                const csrfToken = cookies.csrf_access_token;

                // Set headers with CSRF token
                const config: AxiosRequestConfig = {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': csrfToken || '',
                    },
                };

                const response = await axios.get(`${AppConfig.alluerDockerApiUrl}/projects/${debouncedValue}`, config);

                const message = response.data.meta_data?.message || '';
                

                if (message.includes('project_id') && message.includes('not found')) {
                    setApiError('Name Available'); // Update this to your desired message
                    setfolderValidation("Name Available"); // Update this to your desired message
                    folderValidation == 'Name Available';

                } else if (message.includes('Project successfully obtained')) {
                    setApiError('Name already exists, Please select a new one.');
                    setfolderValidation('Name already exists, Please select a new one.');
                    folderValidation =='Name already exists, please select a new one.';

                } else {
                    setApiError(null); // Handle any other cases
                    setfolderValidation(null); // Handle any other cases
                    folderValidation=='Null'; //


                }
            }  catch (error) {
                if (error.message.includes('project_id') && error.message.includes('not found')) {
                    setApiError('Name Available'); // Update this to your desired message
                    setfolderValidation('Name Available'); // Update this to your desired message
                    folderValidation=='Name Available';
                } else if (error.message.includes('Projects successfully obtained')) {
                    setApiError('Name already exists. Please select a new one.');
                    setfolderValidation('Name already exists. Please select a new one.');
                    folderValidation=='Name already exists';
                } else {
                    setApiError(null); // Handle any other cases
                    setfolderValidation('Null"); //')
                    folderValidation=='Null'; //
                }

            }

    
            
            

//          // Second API call to create a project
//             const csrfToken = cookies.csrf_access_token; // Extract CSRF token from cookies
//              //const accessToken = cookies.access_token_cookie; // Extract Access token from cookies
//             const accessToken = localStorage.getItem('access_token');
//             const projectConfig = {
//                 headers: {
//                     "Content-Type": "application/json",
//                     "X-CSRF-Token": csrfToken || '',
//                     "Authorization": `Bearer ${accessToken}` // Assuming the access token is a Bearer token
//                 },
//                 withCredentials: true // Ensure cookies are sent with the request
//            };


//              //Make sure the API endpoint is correctly formatted
// axios.post(
//      `${AppConfig.alluerDockerApiUrl}projects`,
//          { id: value },
//      projectConfig,
//  );


//const response =   axios.get(`${AppConfig.alluerDockerApiUrl}/projects}`, projectConfig);
//const id = response.data.data?.id || '';

//const id = response.data.id;
       // const projectdata = response.data
        //if (projectdata.id != null) {
          //  valuefromapi='0'
        //}
        //else {
          //  valuefromapi='1'
       // }
                };
                


        checkProjectExistence();
    }, [debouncedValue, cookies.csrf_access_token]); // Added debouncedValue to dependency array


    const displayError =      errorMessage ||apiError;



    return (
        <div>
            <div>Create Folder</div>
            <WppInput
                id="resultFolder"
                name="resultFolder"
                style={{ width: '300px' }}
                value={value}
                onWppChange={handleChange}
                placeholder="Enter Result Folder Name"
            />
            {/* {apiError && <p style={{ color: 'red' }}>{apiError}</p>}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} */}
            {displayError && <p style={{ color: 'red' }}>{displayError}</p>}

        </div>
    );
};
