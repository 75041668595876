import {AOQuestionnaireEndPoints, AOQuestionnaireRequest} from "../../api/audience-origin-questionnaire";

export const getDownloadFile = async (id, fileName) => {
    try {
        console.log("Downloading questionnaire ...")
        console.log(id);
        console.log(fileName);
        const response = await AOQuestionnaireRequest.get(
            `${AOQuestionnaireEndPoints.QuestionnaireList}/${id}/export`,{responseType: 'blob'}
        );
        //console.log(response.headers);
        const downloadUrl = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName + '.xlsx'; // Ensure the extension is .xlsx
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return response;
    } catch (error) {
        return error;
    }

};


