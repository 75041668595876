import React, {memo, useState} from "react";
import {Handle, NodeToolbar, Position} from "reactflow";
import {SurveySummary} from "../../../types";
import styled from "@emotion/styled";
import {Tooltip, TooltipProps, tooltipClasses} from "@mui/material";
import {formatLabel, formattedDate} from "./utils";

const DataProcessingCustomTooltip = styled(
    ({className, ...props}: TooltipProps) => (
        <Tooltip {...props} classes={{popper: className}}/>
    )
)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: 16,
        border: "1px solid #dadde9",
    },
}));

function DisabledReactFlowNode({
                                   data,
                                   isConnectable,
                               }: {
    data: SurveySummary & {
        label: string;
        isInitial: boolean;
        isFinal: boolean;
        toolbarVisible: boolean;
    };
    isConnectable: boolean;
}) {
    const [isVisible, setVisible] = useState(false);
    const getCurrentStepBackground = () => {
        const {status} = data;
        switch (status) {
            case "success":
                return "#63C065";
            case "failed":
                return "red";
            case "partial_success":
                return "orange";
            default:
                return "darkgray";
            //"repeating-linear-gradient(-45deg, lightgrey, lightgrey 5px, white 5px, white 10px)";
        }
    };

    const handleTooltipMouseEnter = () => {
        setVisible(true);
    };

    const handleTooltipMouseLeave = () => {
        setVisible(false);
    };

    const tooltipTemplate = (data: SurveySummary) => {
        if (!data.survey) {
            return <div>{formatLabel(data.step)} Data Not Found</div>;
        }

        if (data.status === "failed") {
            return <div>{formatLabel(data.step)} Step Failed</div>;
        }

        return (
            <div style={{margin: 8}}>
                <div style={{margin: 8}}>Survey: {data.survey}</div>
                {/* <div style={{ margin: 8 }}>Label: {formatLabel(data.step)}</div> */}
                <div style={{margin: 8}}>
                    Load Date: {formattedDate(data.load_time)}
                </div>
                <div style={{margin: 8}}>Market Count: {data.market_count}</div>
                <div style={{margin: 8}}>Status: {formatLabel(data.status)}</div>
            </div>
        );
    };

    return (
        <div
            className="text-updater-node"
            onMouseEnter={handleTooltipMouseEnter}
            onMouseLeave={handleTooltipMouseLeave}
        >
            {!data.isInitial && (
                <Handle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                    style={{background: '#58635b'}}
                />
            )}
            <DataProcessingCustomTooltip
                open={isVisible}
                onClose={handleTooltipMouseLeave}
                onOpen={handleTooltipMouseEnter}
                title={tooltipTemplate(data)}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '5rem',
                        height: '5rem',
                        background: getCurrentStepBackground(),
                        color: '#FFF',
                        borderRadius: '50%', // This makes the node round
                        border: `2px solid ${getCurrentStepBackground()}`
                    }}
                >
                    {data.label}
                </div>
            </DataProcessingCustomTooltip>
            {!data.isFinal && (
                <Handle
                    type="source"
                    position={Position.Right}
                    id="a"
                    isConnectable={isConnectable}
                    style={{background: "#58635b"}}
                />
            )}
        </div>
    );
}

export default memo(DisabledReactFlowNode);
