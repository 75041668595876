import React, {
  
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./QuestionnaireBuilder.module.css";
import { WppButton } from "@platform-ui-kit/components-library-react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { createForm, onFormSubmit, onFormMount } from "@formily/core";
import { FormProvider } from "@formily/react";
import {
  setPreviewerValues,
  setBuilderValues,
  selectBuilderValues,
  setQuestionsMetadata,
  selectQuestionsMetadata,
} from "../../stores/questionnaireSlice";

import { AppTopbar } from "../../components/Dashboard/AppTopbar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { OutLinePanel } from "./OutLinePanel";
import SearchQuestionPanel from "./SearchQuestionPanel";
import { BuilderPanel } from "./BuilderPanel";
import {
  restructureValueData,
  cloneObjectWithArrays,
  parseQuestionsMeta,
  removeItemsStartingWithArrayItems,
  updateSequencesFromMap,
} from "../Utils";
import { QuestionsMetaDnd } from "../../types";




const QuestionnaireBuilder = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateToSurveyPreviewer = async () => {
    try {
      await form.submit();
      navigate("/questionnaire-design/previewer");
    } catch (e) {
      console.log(e);
      //navigate to error page
    }

  };

  const [showSearchPanel, setshowSearchPanel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showResultData, setShowResultData] = useState(false);


  const handleOpenSearchModal = () => setshowSearchPanel(true);
  const handleCloseSearchModal = () => {
    setshowSearchPanel(false)
    setSearchTerm("");
    setShowResultData(false);

  }


  const navigateToSearchPreviewer = () => {

    setshowSearchPanel(true);
  };

  const dispatch = useDispatch();
  const formInitState = useSelector(selectBuilderValues);
  const [currentTab, setCurrentTab] = useState<string>("questions");
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);

  const [questions, setQuestions] = useState<QuestionsMetaDnd[]>(
    useSelector(selectQuestionsMetadata)
  );
  const deletedQuestions = useRef([]);
  const questionOrdersMap = useRef(new Map());

  const form = useMemo(
    () =>
      createForm({
        initialValues: formInitState,
        effects() {
          onFormMount(() => {
            console.log("check values from Redux");
            console.log(formInitState);
          }),
            onFormSubmit(() => {
              console.log("submitting form...");
              console.log(form.values);
              console.log(questionOrdersMap.current);
              console.log(form.values);
              console.log(questionOrdersMap.current);
              const filteredFormValues = removeItemsStartingWithArrayItems(
                deletedQuestions.current,
                JSON.parse(JSON.stringify(form.values))
              );
              const orderedFormValues = updateSequencesFromMap(
                questionOrdersMap.current,
                filteredFormValues
              );
              const questionObjectList = restructureValueData(
                orderedFormValues,
                questionOrdersMap.current
              );
              dispatch(
                setQuestionsMetadata(parseQuestionsMeta(questionObjectList))
              );
              dispatch(
                setPreviewerValues(cloneObjectWithArrays(questionObjectList))
              );
              dispatch(
                setBuilderValues(cloneObjectWithArrays(orderedFormValues))
              );
            });
        },
      }),
    []
  );
  
  const handleCheckboxChange = (event, question) => {
    // Check if the question is already selected
    const isQuestionSelected = selectedQuestions.includes(question);

    if (isQuestionSelected) {
      // If the question is already selected, deselect it
      setSelectedQuestions((prevSelected) =>
        prevSelected.filter((q) => q !== question)
      );
    } else {
      // If the question is not selected, select it
      setSelectedQuestions((prevSelected) => [...prevSelected, question]);
    }

    console.log(selectedQuestions);
  };

  const handleTabChange = async (e) => {
    if (e.detail.value === "outline") {
      try {
        await form.submit();
        setCurrentTab(e.detail.value);
      } catch (e) {
        console.log(e);
        //navigate to error page
      }
    } else {
      setCurrentTab(e.detail.value);
    }
  };

  const handleMouseEnter = () => {
    setCurrentTab("questions");
  };

  const handleQuestionDrop = (questionType: string) => {
    const uuid = uuidv4();
    if (questions && questions.length) {
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        { type: questionType, uuid: uuid, title: null },
      ]);
    } else {
      setQuestions([{ type: questionType, uuid: uuid, title: null }]);
    }
  };

  const handleQuestionDelete = (uuid: string) => {
    const deleteQuestion = (prevQuestions) => {
      // Create a new array for manipulation
      const updatedQuestions = [...prevQuestions];
      const index = updatedQuestions.findIndex(
        (question) => question.uuid === uuid
      );
      updatedQuestions.splice(index, 1);
      const fieldKey1 = uuid + "|question_text";
      const fieldKey2 = uuid + "|question_label";
      //hack solution to remove the required fields preventing the form from submitting
      form.setValuesIn(fieldKey1, "deleted");
      form.setValuesIn(fieldKey2, "deleted");
      deletedQuestions.current = [
        ...deletedQuestions.current,
        prevQuestions[index].uuid,
      ];
      return updatedQuestions;
    };
    setQuestions(deleteQuestion(questions));
  };

  const handleMovedCards = (questions) => {
    questions.forEach((question, index) => {
      questionOrdersMap.current.set(question.uuid, index);
    });
    setQuestions(questions);
  };

  const handleClearField = (
    uuid: string,
    index: number,
    field: string,
    itemIndex: number
  ) => {
    let fieldValues = uuid + "|answers";
    let arrayValues = [...form.values[fieldValues]];
    arrayValues[itemIndex][field] = "";
    form.setValuesIn(fieldValues, arrayValues);
  };

  const handleChange = (
    uuid: string,
    index: number,
    field: string,
    itemIndex: number,
    value: string
  ) => {
    // answer level text area
    if (field.includes("ans_")) {
      let data = [...form.values[`${uuid}|answers`]];
      data[itemIndex][field] = value;
      let fieldValues = uuid + "|answers";
      form.setValuesIn(fieldValues, data);
    }
    //question level text area
    else {
      form.setValuesIn(field, value);
    }
  };

  const IsChecked = (question) => {
    //console.log(e);
    let value = false;
    selectedQuestions.map((questionObj, index) => {
      if (questionObj.question_text == question.question_text) {
        value = true;
      }
    });

    return value;
  };

  const buildFormValue = (uuid: string[], qnsData: any[]) => {
    console.log(qnsData);
    console.log(uuid);
    var formObj: any = {};
   //creating builder value depending on the question type
      formObj.description = "Please Enter Description...";
      formObj.methodology = "Online";
      formObj.type = qnsData[0].survey_type
      formObj.survey_name = "Please Enter Survey Name";
    if(Object.keys(form.getState().values).length > 2){
      formObj.description = form.getState().values.description;
      formObj.methodology = form.getState().values.methodology;
      formObj.type =form.getState().values.type;
      formObj.survey_name = form.getState().values.survey_name;
    }

    let formObjArray = qnsData.map((questionObj, index) => {
      let sequenceProp = uuid[index] + "|sequence";
      if (form.getState().initialValues.type == undefined) {
        formObj[sequenceProp] = index;
      } else {
        formObj[sequenceProp] = Object.keys(formInitState).filter(str => str.includes('sequence')).length
      }
      let typeProp = uuid[index] + "|type";
      formObj[typeProp] = questionObj.type.toLowerCase();
      let qstLabelProp = uuid[index] + "|question_label";
      formObj[qstLabelProp] = questionObj.question_label;
      let qstTextProp = uuid[index] + "|question_text";
      formObj[qstTextProp] = questionObj.question_text;
      let ansProp = uuid[index] + "|answers";
      formObj[ansProp] = [];
      let ansCommentKey = uuid[index] + "|ans_text";
      let ansFilterKey = uuid[index] + "|ans_variable";
      let convertedObjects: any[] = [];
      let convertedObj: any = {};
      let ansCode = uuid[index] + "|ans_code";
      if (qnsData[index].type.toLowerCase() == "single-coded") {
        convertedObjects = questionObj.answers.map((answer, index) => ({
          [ansCode]: answer.ans_code,
          ans_sequence: index + 1,
          [ansCommentKey]: answer.ans_text, // Set ansCommentKey to the constant value
          [ansFilterKey]: answer.ans_variable, // Set ansFilterKey to the constant value
        }));
      } else if (qnsData[index].type.toLowerCase() == "single-coded per row") {
        let row = 0;
        let column = 0;
        convertedObjects = questionObj.answers.map((answer) => ({
          ans_code: parseInt(answer.ans_code),
          ans_type: answer.ans_type,
          ans_sequence: answer.ans_type == "ROW" ? ++row : ++column,
          ans_text: answer.ans_text, // Set ansCommentKey to the constant value
          ans_variable: answer.ans_variable, // Set ansFilterKey to the constant value
        }));
      } else if (qnsData[index].type.toLowerCase() == "multi-coded") {
        convertedObjects = questionObj.answers.map((answer, index) => ({
          [ansCode]: parseInt(answer.ans_code),
          [ansCommentKey]: answer.ans_text, // Set ansCommentKey to the constant value
          [ansFilterKey]: answer.ans_variable, // Set ansFilterKey to the constant value
        }));
      } else if (qnsData[index].type.toLowerCase() == "open-numeric") {
        convertedObjects = questionObj.answers.map((answer, index) => ({
          ansCode: parseInt(answer.ans_code),
          [ansFilterKey]: answer.ans_variable, // Set ansFilterKey to the constant value
          ans_sequence: index + 1,
        }));
      } else if (qnsData[index].type.toLowerCase() == "grid-numeric") {
        let row = 0;
        let column = 0;
        convertedObjects = questionObj.answers.map((answer, index) => ({
          ans_code: parseInt(answer.ans_code),
          ans_filter: answer.ans_filter,
          ans_variable: answer.ans_variable,
          ans_type: answer.ans_type,
          ans_sequence: answer.ans_type == "ROW" ? ++row : ++column,
          ans_text: answer.ans_text
        }));
      } else {
        convertedObjects = questionObj.answers.map((answer, index) => ({
          ans_code: parseInt(answer.ans_code),
          ans_sequence: index + 1,
          [ansCommentKey]: answer.ans_text, // Set ansCommentKey to the constant value
          [ansFilterKey]: answer.ans_variable, // Set ansFilterKey to the constant value
        }));
      }
      console.log(convertedObjects);
      formObj[ansProp] = convertedObjects;
      console.log(formObj);
    });
    return formObj;
  };

  useEffect(() => {
    console.log(form);
  }, [form]);

  function handleAdd(): void {
    handleCloseSearchModal();
    //const uuid = uuidv4();
    const uuidArray = selectedQuestions.map(() => uuidv4());
    let metaDataArray =[];
    if((form.getState().initialValues.type != undefined)){
      let length = Object.keys(formInitState).filter(str => str.includes('sequence')).length;
      for (let i = 0; i < length; i++) {
        var meta ={
        type: formInitState[Object.keys(formInitState).filter(str => str.includes('type'))[i]], // Assuming type and code are properties of each question object
        uuid: Object.keys(formInitState).filter(str => str.includes('question_label'))[i].split("|")[0], // Assuming uuid is generated elsewhere
        title: formInitState[Object.keys(formInitState).filter(str => str.includes('question_label'))[i]],
        index: formInitState[Object.keys(formInitState).filter(str => str.includes('sequence'))[i]], // Assigning index based on the order in the selectedQuestions array
        };
        metaDataArray.push(meta);
      }
      form.deleteInitialValuesIn(formInitState);
      form.deleteValuesIn(formInitState);
      form.reset(); // Reset the form
    }else if (Object.keys(form.getState().values).length > 2){
      let objValues = form.getState().values;
      let length = Object.keys(form.getState().values).filter(str => str.includes('sequence')).length;
      for (let i = 0; i < length; i++) {
        var meta ={
        type: objValues[Object.keys(objValues).filter(str => str.includes('type'))[i]], // Assuming type and code are properties of each question object
        uuid: Object.keys(objValues).filter(str => str.includes('question_label'))[i].split("|")[0], // Assuming uuid is generated elsewhere
        title: objValues[Object.keys(objValues).filter(str => str.includes('question_label'))[i]],
        index: objValues[Object.keys(objValues).filter(str => str.includes('sequence'))[i]], // Assigning index based on the order in the selectedQuestions array
        };
        metaDataArray.push(meta);
      }

    }
    let metaData = selectedQuestions.map((question, index) => ({
      type: question.type, // Assuming type and code are properties of each question object
      uuid: uuidArray[index], // Assuming uuid is generated elsewhere
      title: question.question_label,
      index: metaDataArray.length+index, // Assigning index based on the order in the selectedQuestions array
    }));
    metaData = [...metaDataArray, ...metaData];

    const formObj = buildFormValue(uuidArray, selectedQuestions);
    if (form.getState().initialValues.type != undefined) {
      form.setValues(formObj,"merge")
  } else {
      form.setValues(formObj); // Set values if form doesn't have initial values
  }
    setBuilderValues(formObj);
    setQuestionsMetadata(metaData);
    setQuestions(metaData);
    
    console.log(form);
  
  }

  return (
    <div>
      <AppTopbar> </AppTopbar>
      <FormProvider form={form}>
        <div className={styles["workspace-header"]}>
          <WppButton
              className={styles["workspace-search-button"]}
              onClick={navigateToSearchPreviewer}
              variant='secondary'
          >
            Search Questions Archive
          </WppButton>
          <WppButton
            className={styles["workspace-preview-button"]}
            onClick={navigateToSurveyPreviewer}
            variant='primary'
          >
            Preview Questionnaire
          </WppButton>

        </div>
        <div className={styles["drag-drop-panel"]}>
          <DndProvider backend={HTML5Backend}>
            <OutLinePanel
              handleMovedCards={handleMovedCards}
              handleTabChange={handleTabChange}
              currentTab={currentTab}
            />
            <BuilderPanel
              handleMouseEnter={handleMouseEnter}
              handleQuestionDrop={handleQuestionDrop}
              handleQuestionDelete={handleQuestionDelete}
              handleClearField={handleClearField}
              handleChange={handleChange}
              questions={questions}
              form={form}
            />
            <SearchQuestionPanel
              showPanel={showSearchPanel}
              IsChecked={IsChecked}
              handleAdd={handleAdd}
              value={searchTerm}
              setShowResultData={setShowResultData}
              showResultData={showResultData}
              setSearchTerm={setSearchTerm}
              handleCheckboxChange={handleCheckboxChange}
              handleCloseModal={handleCloseSearchModal}
              handleOpenModal={handleOpenSearchModal}
            ></SearchQuestionPanel>
          </DndProvider>
        </div>
      </FormProvider>
    </div>
  );
};

export default QuestionnaireBuilder;
