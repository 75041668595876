import {
    WppCheckbox,
    WppTypography,
} from "@platform-ui-kit/components-library-react";
import React from "react";
import styles from "../Dashboard/data-ingestion-workflow.module.scss";


export const FileNamingConvStep = ({checked, onChange, configName}) => {
    const workflowConfig: any = configName;
    return (
        <WppTypography type="l-body">
            {workflowConfig.label}
            <div style={{marginLeft: "2rem", marginBottom: "2rem"}}>
                {workflowConfig.instructions.map((item: any) => (
                    <div style={{marginTop: "0.25rem"}}>
                        <div>
                            <b>{item.key}</b>{" "}
                            <span className={styles.highlightText}>{item.description}</span>{" "}
                            {item.variables && (
                                <ul style={{marginTop: 0}}>
                                    {item.variables &&
                                        item.variables.map((variable) => (
                                            <li>
                        <span className={styles.highlightText}>
                          {variable.key}
                        </span>{" "}
                                                → {variable.description}
                                            </li>
                                        ))}
                                </ul>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {workflowConfig.validation && (
                <WppCheckbox
                    required={true}
                    checked={checked}
                    indeterminate={false}
                    labelConfig={{
                        text: workflowConfig.validation.label,
                    }}
                    onWppChange={(e: CustomEvent) => onChange(e.detail.checked)}
                />
            )}
        </WppTypography>
    );
};
