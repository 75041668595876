import React from "react";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
  maxWidth: 1000,
}));

const allureDockerReportsDropDown = (props) => {
  return (
    <StyledFormControl variant="standard">
      <Select
        variant="standard"
        native
        value={props.reportSelected}
        onChange={props.selectReport}
        inputProps={{
          id: "select-multiple-native",
        }}
      >
        {props.reports.map((report) => (
          <option key={report.linkValue} value={report.linkValue}>
            {report.linkVisibleText}
          </option>
        ))}
      </Select>
    </StyledFormControl>
  );
};
export default allureDockerReportsDropDown;
