import React from "react";
import styles from "./QuestionnaireBuilder.module.css";
import { WppTabs, WppTab } from "@platform-ui-kit/components-library-react";
import { OutLineList } from "./OutLineList";
import { DraggableQuestionIcon } from "../QuestionComponents/DraggableQuestionIcon";
import { QuestionsMetaDnd } from "../../types";

type OutLinePanelProps = {
  handleTabChange: (e) => void;
  currentTab: string;
  handleMovedCards: (questions: Array<QuestionsMetaDnd>) => void;
};

export const OutLinePanel: React.FC<OutLinePanelProps> = (props) => {
  return (
    <div>
      <div className={styles["out-panel-sidebar"]}>
        <div className={styles["out-panel-components"]}>
          <WppTabs value={props.currentTab} onWppChange={props.handleTabChange}>
            <WppTab value="questions">Questions</WppTab>
            <WppTab value="outline">Outline</WppTab>
          </WppTabs>
          {
            {
              questions: (
                <div>
                  <DraggableQuestionIcon type="free-text" />
                  <DraggableQuestionIcon type="open-numeric" />
                  <DraggableQuestionIcon type="single-coded" />
                  <DraggableQuestionIcon type="multi-coded" />
                  <DraggableQuestionIcon type="grid-numeric" />
                  <DraggableQuestionIcon type="single-coded-per-row" />


                </div>
              ),
              outline: (
                <OutLineList handleMovedCards={props.handleMovedCards} />
              ),
            }[props.currentTab]
          }
        </div>
      </div>
    </div>
  );
};
