import React from "react";
import {
    WppTypography,
    WppIconTableSort,
} from "@platform-ui-kit/components-library-react";

const HeaderCell = (props) => {
    const handleHeaderCellClick = () => {
        if (!props.enableSorting) return;

        const items = ["asc", "desc", null];
        const selectedSort = props.column.getSort();
        const selectedSortIndex = selectedSort ? items.indexOf(selectedSort) : -1;
        const nextSortType =
            selectedSortIndex === -1 || selectedSortIndex === items.length - 1
                ? "asc"
                : items[selectedSortIndex + 1];

        props.setSort(nextSortType);
    };

    const getColor = (isActive) =>
        isActive
            ? "var(--wpp-iconography-color-active)"
            : "var(--wpp-grey-color-400)";

    return (
        <div className="ag-header-cell-comp" onClick={handleHeaderCellClick}>
            <WppTypography type="s-strong">{props.displayName}</WppTypography>
            {props.enableSorting && (
                <div className="ag-sorting">
                    <WppIconTableSort color={getColor(props.column.isSortAscending())}/>
                </div>
            )}
        </div>
    );
};

export default HeaderCell;
