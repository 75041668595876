import { AxiosInstance } from "axios";
import apiHelper from "./apiHelper";
import { AppConfig } from "../AppConfig";

//const QUESTIONNAIRE_BASE_URL = `http://localhost:9292/audience-origin-questionnaire/v1`;
//const QUESTIONNAIRE_BASE_URL = `http://localhost:8010/proxy/audience-origin-questionnaire/v1`
const QUESTIONNAIRE_BASE_URL = `${AppConfig.aoQuestionnaireApiUrl}`;

enum EndPoints {
    QuestionnaireList = '/questionnaires',
    CompareQuestionnaire = '/compare-questionnaires'
}

const request: AxiosInstance = apiHelper(QUESTIONNAIRE_BASE_URL)

export { request as AOQuestionnaireRequest, EndPoints as AOQuestionnaireEndPoints };

