import {AG_GRID_CELL_STYLE} from "../../../constants/ag-grid-defaults";
import DownloadButtonComponent from "./DownloadButton";
import weighDownloadButtonComponent from "./weighdownloadbutton";





const LOADED_FILES_COLUMN_DEFS: any[] = [
    {
        headerName: "Survey",
        field: "survey",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Ingested Markets",
        field: "market_count",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 250,
    },
    {
        headerName: "Expected Markets",
        field: "expected_market_count",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 250,
    },
    {
        headerName: "File Names",
        field: "filenames",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        tooltipComponentParams: {color: '#ffffff'},
        maxWidth: 420,
    },
    {
        headerName: "Market Codes",
        field: "market_codes",
        tooltipComponentParams: {color: '#ffffff'},
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 360,
    },
    {
        headerName: "Missing Markets",
        field: "expected_market_codes",
        tooltipComponentParams: {color: '#ffffff'},
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 360,
        valueFormatter: ({data}) => {
            const expectedMarketCodesString: string = data.expected_market_codes ?? '';
            const expectedMarketCodes = expectedMarketCodesString.split(',').map(val => val.trim());
            const marketCodesString: string = data.market_codes ?? '';
            const marketCodes = marketCodesString.split(',').map(val => val.trim());
            const missingMarketCodes = expectedMarketCodes.filter(code => !marketCodes.includes(code)).join(',');
            return missingMarketCodes ? missingMarketCodes : '-';
        }
    },

];

const REJECTION_FILES_COLUMN_DEFS: any[] = [
    {
        headerName: "Survey",
        field: "survey",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Rejection Code",
        field: "rejection_code",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Variables",
        field: "variables",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 640,
    },
    {
        headerName: "Markets",
        field: "markets",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 640,
    },
];


const NET_META_FILES_COLUMN_DEFS: any[] = [
    {
        headerName: "Survey",
        field: "SURVEY",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Status",
        field: "PROCESSING_STATUS",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 100,
    },
    {
        headerName: "Uploaded Time",
        field: "PROCESSING_TIME",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Input Files",
        field: "INPUT_FILES",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 640,
    },
    {
        headerName: "Info",
        field: "INFO",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 400,
    },
];

const NET_META_FILES_DB_COLUMN_DEFS: any[] = [
    {
        headerName: "Survey",
        field: "SURVEY",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Config Files",
        field: "CONFIG_FILE_NAME",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 600,
    },
    {
        headerName: "Latest Version",
        field: "LATEST_VERSION",
        sortable: true,
   
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Date",
        field: "DATE",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 400,
    },
    {
        headerName: "Download",
        field: "DOWNLOAD",
        cellRenderer: DownloadButtonComponent,
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 400,
    },
];

const WEIGHT_META_FILES_COLUMN_DEFS: any[] = [
    {
        headerName: "Survey",
        field: "SURVEY",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Status",
        field: "PROCESSING_STATUS",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 100,
    },
    {
        headerName: "Uploaded Time",
        field: "PROCESSING_TIME",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Input Files",
        field: "INPUT_FILES",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 640,
    },
    {
        headerName: "Info",
        field: "INFO",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 400,
    },
];

const WEIGHT_META_FILES_DB_COLUMN_DEFS: any[] = [
    {
        headerName: "Survey",
        field: "SURVEY",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Config Files",
        field: "CONFIG_FILE_NAME",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 600,
    },
    {
        headerName: "Latest Version",
        field: "LATEST_VERSION",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Date",
        field: "DATE",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 400,
    },
    {
        headerName: "Download",
        field: "DOWNLOAD",
        cellRenderer: weighDownloadButtonComponent,
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
];

const VARIABLE_ORDERING_COLUMN_DEFS: any[] = [
    {
        headerName: "Survey Type",
        field: "REPORT_TYPE",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Count of Variable order defined",
        field: "VARIABLE_COUNT",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 600,
    },
    {
        headerName: "Version",
        field: "VERSION",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 600,
    },

];

const NET_DATA_CALCULATION_COLUMN_DEFS: any[] = [
    {
        headerName: "Survey",
        field: "survey",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 100
    },
    {
        headerName: "Ingested Markets",
        field: "market_count",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 250,
    },
    {
        headerName: "Expected Markets",
        field: "expected_market_count",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 250,
    },
    {
        headerName: "Market Codes",
        field: "market_codes",
        tooltipComponentParams: {color: '#ffffff'},
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 360,
    },
    {
        headerName: "Missing Markets",
        field: "expected_market_codes",
        tooltipComponentParams: {color: '#ffffff'},
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 360,
        valueFormatter: ({data}) => {
            const expectedMarketCodesString: string = data.expected_market_codes ?? '';
            const expectedMarketCodes = expectedMarketCodesString.split(',').map(val => val.trim());
            const marketCodesString: string = data.market_codes ?? '';
            const marketCodes = marketCodesString.split(',').map(val => val.trim());
            const missingMarketCodes = expectedMarketCodes.filter(code => !marketCodes.includes(code)).join(',');
            return missingMarketCodes ? missingMarketCodes : '-';
        }
    }
]

const WEIGHT_SNOWFLAKE_CALCULATION_RUN_COLUMN_DEFS: any[] = [
    {
        headerName: "Year",
        field: "YEAR",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Wave",
        field: "WAVE_LABEL",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 100,
    },
    {
        headerName: "Markets",
        field: "MARKET_CODES",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Run ID",
        field: "RUN_ID",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 640,
    },
    {
        headerName: "Status",
        field: "PROCESSING_STATUS",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 400,
    },
    {
        headerName: "Info",
        field: "INFO",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 400,
    },
];


const WEIGHT_SNOWFLAKE_CALCULATION_COLUMN_DEFS: any[] = [
    {
        headerName: "Year",
        field: "YEAR",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Wave",
        field: "WAVE_LABEL",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Markets",
        field: "MARKET_CODE",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 600,
    },
    {
        headerName: "Market Count",
        field: "MARKET_COUNT",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    }
];

const EXPORT_STATISTICS_COLUMN_DEFS: any[] = [
    {
        headerName: "Report Type",
        field: "REPORT_TYPE",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Table Name",
        field: "TABLE_NAME",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 400,
    },
    {
        headerName: "Export Time",
        field: "EXPORT_DATE",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Export Count",
        field: "EXPORT_COUNT",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Input Params",
        field: "INPUT_PARAMS",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    }
];


const configMap = {
    rejectionTableConfig: REJECTION_FILES_COLUMN_DEFS,
    latestDataLoadTableConfig: LOADED_FILES_COLUMN_DEFS,
    netMetaFilesTableConfig: NET_META_FILES_COLUMN_DEFS,
    netMetaFilesDBInfoTableConfig: NET_META_FILES_DB_COLUMN_DEFS,
    weightMetaFilesTableConfig: WEIGHT_META_FILES_COLUMN_DEFS,
    weightMetaFilesDBInfoTableConfig: WEIGHT_META_FILES_DB_COLUMN_DEFS,
    variableOrderTableConfig: VARIABLE_ORDERING_COLUMN_DEFS,
    netDataCalculationTableConfig: NET_DATA_CALCULATION_COLUMN_DEFS,
    weightSnowflakeToSnowflakeRunConfig: WEIGHT_SNOWFLAKE_CALCULATION_RUN_COLUMN_DEFS,
    weightSnowflakeToSnowflakeTableConfig: WEIGHT_SNOWFLAKE_CALCULATION_COLUMN_DEFS,
    exportStatisticsRunConfig: EXPORT_STATISTICS_COLUMN_DEFS,
};

export const getColumnConfigForTable = (configName: string) => {
    return configMap[configName] ? configMap[configName] : undefined;
};