

import React, {useState} from 'react';
import styles from './QuestionnaireBuilder.module.css';
import {QuestionsMetaDnd} from "../../types";
import {useSelector} from "react-redux";
import {selectQuestionsMetadata} from "../../stores/questionnaireSlice";
import {Button} from "antd";
import {DraggableQuestionOutlineTile} from "../QuestionComponents/DraggableQuestionOutlineTile";
import {WppIconApprovals} from "@platform-ui-kit/components-library-react";

type OutLineListProps = {
    handleMovedCards: ( questions: Array<QuestionsMetaDnd>) => void;
}

export const OutLineList: React.FC<OutLineListProps> = (props) => {


    const [questionTiles, setQuestionTiles] = useState<Array<QuestionsMetaDnd>>(useSelector(selectQuestionsMetadata));
    const [disabledSync, setDisabledSync] = useState<boolean>(true);

    const moveQuestion = (dragIndex: number, hoverIndex: number) => {
        console.log("entering drop...");
        const dragQuestion = questionTiles[dragIndex];
        const newQuestions = [...questionTiles];
        newQuestions.splice(dragIndex, 1);
        newQuestions.splice(hoverIndex, 0, dragQuestion);
        setQuestionTiles(newQuestions);
        setDisabledSync(false);
    };

    const handleSwap = () => {
        props.handleMovedCards(questionTiles);
    }
    return (
        <div>
            <div className={styles["outline-sync-button"]} >
            <Button  disabled={disabledSync} icon={<WppIconApprovals/>} onClick={handleSwap}>
            </Button>
            </div>
        <div className={styles["outline-list"]}>
            {questionTiles&&questionTiles.map((question, index) => (
                <DraggableQuestionOutlineTile key={question.uuid} uuid={question.uuid} title={question.title} index={index} moveQuestion={moveQuestion} />
            ))}
        </div>
        </div>
    );
}