import React, {useRef, useState} from "react";
import {WppTypography} from "@platform-ui-kit/components-library-react";
import {useQuery} from "@tanstack/react-query";
import {AudienceOriginRequest, EndPoints} from "../../../api/audience-origin";
import {MetadataStatistics} from "../../../types";
import {IngestionGrid} from "../IngestionGrid/IngestionGrid";
import {AG_GRID_CELL_STYLE} from "../../../constants/ag-grid-defaults";

function useMetadataStatistics() {
    return useQuery({
        queryKey: ["metadataStatistics"],
        queryFn: async () => {
            const {data} = await AudienceOriginRequest.post<MetadataStatistics[]>(
                EndPoints.MetadataStatistics
            );
            return data;
        },
    });
}

const columnDefs: any[] = [
    {
        headerName: "Survey",
        field: "survey",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Batch Id",
        field: "batch_id",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    },
    {
        headerName: "Market Codes",
        field: "market_codes",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    },
    {
        headerName: "Market Count",
        field: "market_count",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 140,
    },
];

const MetadataStatisticsGrid = () => {
    const {status, data, error, isFetching} = useMetadataStatistics();

    return (
        <div style={{marginTop: "1rem"}}>
            <WppTypography
                type="l-body"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: `0.5rem 0`,
                }}
            >
                Metadata Statistics
            </WppTypography>
            <IngestionGrid columns={columnDefs} data={data} status={status}/>
        </div>
    );
};

export default MetadataStatisticsGrid;
