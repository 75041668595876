import React, {useState} from "react";
import {
    WppStepper,
    WppStep,
    WppTopbar,
    WppButton,
    WppTypography,
} from "@platform-ui-kit/components-library-react";
import {useNavigate} from "react-router-dom";
import styles from "../Dashboard/data-ingestion-workflow.module.scss";
import {InstructionStep} from "../Common/InstructionStep";
import {TriggerDagStep} from "../Common/TriggerDagStep";
import {ExportDagsConstants} from "./ExportDagsConstants";

const WORKFLOW_STEPS = 2;

export const ExportMarketWorkflow = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [highestStep, setHighestStep] = useState(1);
    const [isDagSuccess, setIsDagSuccess] = useState(false);
    const [instructionStepChecked, setInstructionStepChecked] =
        useState(false);
    const [
        isInstructionStepPristine,
        setIsInstructionStepPristine,
    ] = useState(true);

    const handleNextStep = () => {
        if (currentStep === WORKFLOW_STEPS) {
            return;
        }
        if (currentStep === 1) {
            setIsInstructionStepPristine(false);

            if (!instructionStepChecked) {
                return;
            }
        }

        const step = currentStep + 1;
        setCurrentStep(step);
        if (step > highestStep) {
            setHighestStep(step);
        }
    };
    const handlePreviousStep = () => {
        if (currentStep === 1) {
            return;
        }

        setCurrentStep(currentStep - 1);
    };

    const onDagSuccess = () => {
        setIsDagSuccess(true);
    };

    const getSelectedPage = () => {
        switch (currentStep) {
            case 1:
                return (
                    <InstructionStep
                        checked={instructionStepChecked}
                        onChange={setInstructionStepChecked}
                        title="Please read the instructions below to proceed with the Market Export Run:"
                        context={["This is used to trigger single market export in snowflake DB.",
                            "Please make sure all data are loaded/calculated/updated before running this DAG.",
                            "This pipeline will generate market level tables for the specified market.",
                        ]}
                        checkboxText="I have read and understood the instructions."
                        links={[{
                            label: "Confluence Wiki",
                            link: ExportDagsConstants.marketExportWikiLink,
                            context: "Find more details here"
                        }]}
                    />
                );
            case 2:
                return (
                    <TriggerDagStep
                        onDagSuccess={onDagSuccess}
                        configName={ExportDagsConstants.triggerExportMarketDAG}
                        survey=''
                    />
                );
        }
        return (
            <div>
                <h3>Page {currentStep}</h3>
            </div>
        );
    };

    const handleStepClick = (event) => {
        if (isDagSuccess) {
            return;
        }

        const step = event.detail.index;
        if (step <= highestStep) {
            setCurrentStep(step);
        }
    };

    const navigateToMIHome = () => {
        navigate("/data-processing/export-data");
    };

    return (
        <div>
            <WppTopbar navigation={[]} style={{margin: "1rem"}}>
                <div slot="app">
                    <WppTypography type="l-midi" style={{width: "20rem"}}>
                        Export : Market (Optional: By Wave)
                    </WppTypography>
                </div>
            </WppTopbar>
            <div
                style={{
                    display: "flex",
                    margin: "2rem",
                }}
            >
                <WppStepper activeStep={currentStep} style={{width: "19rem"}}>
                    <WppStep
                        onWppStepChange={handleStepClick}
                        error={
                            !instructionStepChecked &&
                            !isInstructionStepPristine
                        }
                    >
                        <p slot="label">Instructions</p>
                    </WppStep>
                    <WppStep onWppStepChange={handleStepClick}>
                        <p slot="label">Trigger DAG</p>
                    </WppStep>
                </WppStepper>
                <div className={styles["stepper-container"]}>{getSelectedPage()}</div>
            </div>

            <div
                style={{
                    display: "flex",
                    margin: "2rem",
                    justifyContent: "flex-end",
                }}
            >
                {isDagSuccess ? (
                    <WppButton variant="secondary" onClick={navigateToMIHome}>
                        Go To Export Home
                    </WppButton>
                ) : (
                    <>
                        <WppButton
                            variant="secondary"
                            onClick={handlePreviousStep}
                            disabled={isDagSuccess}
                            style={{marginRight: "1rem"}}
                        >
                            Previous Step
                        </WppButton>
                        <WppButton onClick={handleNextStep} disabled={isDagSuccess}>
                            Next Step
                        </WppButton>
                    </>
                )}
            </div>
        </div>
    );
};
