import React from "react";
import {
    WppTypography,
    WppCheckbox,
} from "@platform-ui-kit/components-library-react";


type Props = {
    checked: boolean;
    onChange: (value: boolean) => void;
    title: string;
    links: { label: string, link: string, context: string }[];
    checkboxText: string;
};

export const UploadFilesStep = (props: Props) => {
    return (
        <WppTypography type="l-body">
            <div style={{marginBottom: `1rem`}}>
                <h3>{props.title}</h3>
                <ul>
                    {props.links.map((item, index) => (
                        <li key={index}>
                            {item.context}: <a href={item.link} target="_blank">{item.label}</a>
                        </li>
                    ))}
                </ul>
            </div>

            <WppCheckbox
                required={true}
                checked={props.checked}
                indeterminate={false}
                labelConfig={{
                    text: props.checkboxText,
                }}
                onWppChange={(e: CustomEvent) => props.onChange(e.detail.checked)}
            />
        </WppTypography>
    );
};
