import {
    WppCheckbox,
    WppTypography,
} from "@platform-ui-kit/components-library-react";
import React from "react";
import styles from "./new-metadata-ingestion-workflow.module.scss";

type Props = {
    checked: boolean;
    onChange: (value: boolean) => void;
};

export const RenameSpecFileStep = (props: Props) => {
    const handleCheckboxChange = (event: CustomEvent) => {
        props.onChange(event.detail.checked);
    };

    return (
        <WppTypography type="l-body">
            Verify / Prepare specification files:
            <div style={{marginLeft: "2rem"}}>
                <div style={{marginTop: "1rem"}}>
                    <b>File naming Convention:</b>{" "}
                    <span className={styles.highlightText}>
            {"AO{year}{wave}SPEC_{additional_info}.xlsx"}
          </span>
                    <ul style={{marginTop: 0}}>
                        <li>
                            <span className={styles.highlightText}>{"{year}"}</span> → 2 digit
                            year like 21, 22
                        </li>
                        <li>
                            <span className={styles.highlightText}>{"{wave}"}</span> → Wave Label
                            like W1, W2, OFFLINEW1, TEENW1
                        </li>
                        <li>
              <span className={styles.highlightText}>
                {"{additional_info}"}
              </span>
                            → Any optional information user wants to include like datetime
                        </li>
                    </ul>
                </div>
                <div>
                    <b>Sheets:</b> Ensure that file contains required tabs/sheets and
                    columns in excel file.
                    <ul style={{marginTop: 0}}>
                        <li>
                            Sheet Name: SPSS spec
                            <ul>
                                <li>Required Columns : "Variable new", "Filter"</li>
                            </ul>
                        </li>
                        <li>
                            Sheet Name: Markets
                            <ul>
                                <li>Required Columns: "Market Code"</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div style={{marginTop: `1rem`}}>
                <WppCheckbox
                    required={true}
                    checked={props.checked}
                    indeterminate={false}
                    labelConfig={{
                        text: "Specification files updated successfully",
                    }}
                    onWppChange={handleCheckboxChange}
                />
            </div>
        </WppTypography>
    );
};
