import React, {useEffect, useState} from "react";
import {AudienceOriginRequest, EndPoints} from "../../../api/audience-origin";
import {useQuery} from "@tanstack/react-query";
import {MetadataDiff, MetadataDiffSheet} from "../../../types";
import {
    WppAccordion,
    WppSpinner,
    WppTypography,
} from "@platform-ui-kit/components-library-react";
import {MetadataDiffGrid} from "../MetadataIngestion/MetadataDiffGrid";
import styles from "../MetadataIngestion/new-metadata-ingestion-workflow.module.scss";
import {IngestionGrid} from "../IngestionGrid/IngestionGrid";
import {AG_GRID_CELL_STYLE} from "../../../constants/ag-grid-defaults";



type DiffFileType = "DIC" | "SPEC";

// Net variable metadata ingestion diff file
function useMetadataDiff(gcsFileLocation: string, year: number, wave: string) {
    return useQuery({
        queryKey: [`metadataDiff`],
        queryFn: async () => {
            const {data} = await AudienceOriginRequest.get<MetadataDiff>(
                `${EndPoints.NetMetadataDiff}?gcsFileLocation=${gcsFileLocation}&wave=${wave}&year=${year}`
            );
            return data;
        },
        retry: false,
    });
}

const columnDefs: any[] = [
    {
        headerName: "File",
        field: "file",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Op",
        field: "op",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    },
    {
        headerName: "Path",
        field: "path",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    }
];
const modfiyColumnDefs: any[] = [
    {
        headerName: "File",
        field: "file",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Op",
        field: "op",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    },
    {
        headerName: "Path",
        field: "path",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    },
    {
        headerName: "NewValue",
        field: "newValue",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    },
    {
        headerName: "OldValue",
        field: "oldValue",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    }
];
export const MetadataDiffStep = (props: {
    wave: string;
    gcsFileLocation: string;
    year: number
    onSuccess?: (value: boolean) => void;
}) => {
    const {status, data, error, isFetching} = useMetadataDiff(
        props.gcsFileLocation,
        props.year,
        props.wave,
    );

    useEffect(() => {
        if (props.onSuccess) {
            props.onSuccess(isFetching ? false : !error);
        }
    }, [error, data, isFetching]);
    var addData, deleteData, modifyData;
    const [isAddedOpen, setIsAddedOpen] = useState(false);
    const [isModifiedOpen, setIsModifiedOpen] = useState(false);
    const [isDeletedOpen, setIsDeletedOpen] = useState(false);
    if (data) {
        addData = data.content.filter(data => data.op == "add");
        deleteData = data.content.filter(data => data.op == "remove");
        modifyData = data.content.filter(data => data.op == "replace");
    }
    const formatGridColmns = (
        columns: { headerName: string; field: string }[]
    ) => {
        const VARIABLE_NEW_COLUMN = "Variable new";
        const variableNewColumn = columns.find(
            (c) => c.field === VARIABLE_NEW_COLUMN
        );
        if (variableNewColumn) {
            columns = columns.filter((c) => c.field !== VARIABLE_NEW_COLUMN);
            columns.unshift(variableNewColumn);
        }

        return columns;
    };

    const getDefaultColumnDefs = (gridData: any[]) => {
        if (gridData.length === 0) {
            return [];
        }

        const [first] = gridData;
        return Object.keys(first).map((val) => ({
            headerName: val,
            field: val,
            tooltipField: val,
            tooltipComponentParams: {color: "#ececec"},
        }));
    };

    const getAddedGridSection = (rowData: MetadataDiffSheet) => {
        if (rowData.added.length === 0) {
            return <></>;
        }

        const [diffData] = rowData.added;
        const gridData = Object.values(diffData);
        let columnDefs: any[] = [];
        switch (rowData.name) {
            case "Variables":
                columnDefs = [
                    "Variable",
                    "Position",
                    "Label",
                    "Measurement Level",
                    "Role",
                    "Column Width",
                    "Alignment",
                    "Print Format",
                    "Write Format",
                ].map((val) => ({
                    headerName: val,
                    field: val,
                    tooltipField: val,
                    tooltipComponentParams: {color: "#ececec"},
                }));
                break;
            case "Values":
                columnDefs = ["Variable", "Value", "Label"].map((val) => ({
                    headerName: val,
                    field: val,
                    tooltipField: val,
                    tooltipComponentParams: {color: "#ececec"},
                }));
                break;
            case "SPSS spec":
                columnDefs = ["Variable new", "Filter"].map((val) => ({
                    headerName: val,
                    field: val,
                    tooltipField: val,
                    tooltipComponentParams: {color: "#ececec"},
                }));
                break;
            case "Markets":
                columnDefs = ["Market Code"].map((val) => ({
                    headerName: val,
                    field: val,
                    tooltipField: val,
                    tooltipComponentParams: {color: "#ececec"},
                }));
                break;
            default:
                columnDefs = getDefaultColumnDefs(gridData);
                break;
        }

        return gridTemplate("Added", gridData, columnDefs);
    };

    const getModifiedGridSection = (rowData: MetadataDiffSheet) => {
        if (rowData.modified.length === 0) {
            return <></>;
        }

        const gridData = rowData.modified
            .map((row) => {
                const [key] = Object.keys(row);
                return {key, rowDetails: row[key] as unknown as any[]};
            })
            .reduce((previous, {key, rowDetails}) => {
                const formattedRow = rowDetails.map((row) => ({
                    key,
                    fieldName: row.field_name,
                    oldValue: row.old_value,
                    newValue: row.new_value,
                }));

                return [...previous, ...formattedRow];
            }, [])
            .sort((a, b) => a.key.localeCompare(b.key));
        let columnDefs: any[] = [
            {
                headerName: "Key",
                field: "key",
                tooltipField: "key",
                tooltipComponentParams: {color: "#ececec"},
            },
            {
                headerName: "Field Name",
                field: "fieldName",
                tooltipField: "fieldName",
                tooltipComponentParams: {color: "#ececec"},
            },
            {
                headerName: "Old Value",
                field: "oldValue",
                tooltipField: "oldValue",
                tooltipComponentParams: {color: "#ececec"},
            },
            {
                headerName: "New Value",
                field: "newValue",
                tooltipField: "newValue",
                tooltipComponentParams: {color: "#ececec"},
            },
        ];

        return gridTemplate("Modified", gridData, columnDefs);
    };

    const getDeletedGridSection = (rowData: MetadataDiffSheet) => {
        if (rowData.deleted.length === 0) {
            return <></>;
        }

        const [diffData] = rowData.deleted;
        const gridData = Object.values(diffData);
        let columnDefs: any[] = [];
        switch (rowData.name) {
            case "Variables":
                columnDefs = [
                    "Variable",
                    "Position",
                    "Label",
                    "Measurement Level",
                    "Role",
                    "Column Width",
                    "Alignment",
                    "Print Format",
                    "Write Format",
                ].map((val) => ({
                    headerName: val,
                    field: val,
                    tooltipField: val,
                    tooltipComponentParams: {color: "#ececec"},
                }));
                break;
            case "Values":
                columnDefs = ["Variable", "Value", "Label"].map((val) => ({
                    headerName: val,
                    field: val,
                    tooltipField: val,
                    tooltipComponentParams: {color: "#ececec"},
                }));
                break;
            case "SPSS spec":
                columnDefs = ["Variable new", "Filter"].map((val) => ({
                    headerName: val,
                    field: val,
                    tooltipField: val,
                    tooltipComponentParams: {color: "#ececec"},
                }));
                break;
            case "Markets":
                columnDefs = ["Market Code"].map((val) => ({
                    headerName: val,
                    field: val,
                    tooltipField: val,
                    tooltipComponentParams: {color: "#ececec"},
                }));
                break;
            default:
                columnDefs = getDefaultColumnDefs(gridData);
                break;
        }

        return gridTemplate("Deleted", gridData, columnDefs);
    };

    const gridTemplate = (label: string, gridData: any[], columnDefs: any[]) => {
        console.log(gridData, columnDefs);
        return (
            <WppAccordion size="l">
                <WppTypography type="l-body" slot="header">
                    {label}
                </WppTypography>

                {gridData.length > 0 ? (
                    <MetadataDiffGrid
                        data={gridData}
                        columns={formatGridColmns(columnDefs)}
                    />
                ) : (
                    <WppTypography>No Data</WppTypography>
                )}
            </WppAccordion>
        );
    };

    const getFileName = (file: string): string => {
        const fileStringSplit = file.split("/");
        if (fileStringSplit.length > 0) {
            return fileStringSplit[fileStringSplit.length - 1];
        }
        return "";
    };

    const getErrorMessage = (error: any) => {
        console.log(error);
        return (
            error?.response?.data?.message ??
            error?.response?.data?.error ??
            error.message??
            "Something went wrong, please refresh."
        );
    };

    return (
        <div style={{overflow: "scroll", height: "100%", width: "100%"}}>
            {isFetching ? (
                <div className={styles.centerContent}>
                    <WppSpinner size="m"/>
                </div>
            ) : error || !data ? (
                <div className={styles.centerContent}>
                    <WppTypography>
                        <strong>Warning: </strong>
                        {getErrorMessage(error)}
                    </WppTypography>
                </div>
            ) : (
                <div>
                    <div style={{margin: "2rem"}}>
                        {data.files[0] && (
                            <div style={{marginBottom: "0.5rem"}}>
                                <WppTypography type="l-midi">
                                    Old File (Processed):
                                </WppTypography>{" "}
                                <WppTypography type="l-body" className={styles.highlightText}>
                                    {getFileName(data.files[0])}
                                </WppTypography>
                            </div>
                        )}
                        {data.files[1] && (
                            <div>
                                <WppTypography type="l-midi">
                                    New File (Pre-Processed):
                                </WppTypography>{" "}
                                <WppTypography type="l-body" className={styles.highlightText}>
                                    {getFileName(data.files[1])}
                                </WppTypography>
                            </div>
                        )}
                    </div>
                    <div style={{marginTop: "1rem", marginLeft: "2rem", marginRight: "3rem"}}>
                        <WppTypography
                            type="l-body"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: `0.5rem 0`,
                            }}
                        >

                        </WppTypography>
                        <div style={{marginBottom: '20px'}}>
                            <WppAccordion size="m">
                                <WppTypography type="m-strong" slot="header">Added</WppTypography>

                                <IngestionGrid columns={columnDefs} data={addData} status={status}/>
                            </WppAccordion>
                        </div>

                        <div style={{marginBottom: '20px'}}>
                            <WppAccordion size="m">
                                <WppTypography type="m-strong" slot="header">Modified</WppTypography>

                                <IngestionGrid columns={modfiyColumnDefs} data={modifyData} status={status}/>
                            </WppAccordion>

                        </div>

                        <div style={{marginBottom: '20px'}}>
                            <WppAccordion size="m">
                                <WppTypography type="m-strong" slot="header">Deleted</WppTypography>

                                <IngestionGrid columns={columnDefs} data={deleteData} status={status}/>
                            </WppAccordion>

                        </div>

                    </div>
                    {/* {data.sheet.map((diff) => {
                        return (
                            <div key={diff.name} style={{margin: "2rem"}}>
                                <WppTypography type="l-midi" slot="header">
                                    {diff.name}
                                </WppTypography>

                                {getAddedGridSection(diff)}

                                {getModifiedGridSection(diff)}

                                {getDeletedGridSection(diff)}
                            </div>
                        );
                    })} */}
                </div>
            )}
        </div>
    );
};
