import React, { Component } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 3, 0, 3),
  display: "flex",
  overflow: "auto",
  flexDirection: "column",
}));

class AllureDockerNotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <StyledPaper>
              <Typography variant="h1" gutterBottom align="center">
                PAGE NOT FOUND
              </Typography>
            </StyledPaper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default AllureDockerNotFound;
