export enum SurveySummaryStatus {
    Meta = 'meta',
    Raw = 'raw',
    Ascription = 'ascription',
    Net = 'net',
    Weight = 'weight'
}

export type SurveySummary = {
    load_time: string;
    market_count: number;
    status: 'success' | 'failed' | 'partial_success';
    step: SurveySummaryStatus;
    survey: string;
}

export type SurveyWaveType = {
    MARKET_CODES: any;
    WAVE: any;
    YEAR: any;
    SURVEY_NAME: any;
    Market_count: number,
    Market_Codes: string,
    Survey_name: string,
    Wave: string,
    Year: number
}