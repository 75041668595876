import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import styles from './QuestionnairePreviewer.module.css';
import {selectPreviewerValues} from "../../stores/questionnaireSlice";
import {removeKeysFromObject, convertQuestionJsonToArray} from "../Utils";
import {addAnsVariable} from "../Utils/SchemaHelpers";


const QuestionnaireSchemaPreviewer: React.FC = () => {

    const builderFormValues = useSelector(selectPreviewerValues);
    const [schemaJson, setSchemaJson]=useState(null);

    useEffect(()=>{

        const keysToRemove = ['survey_name', 'description', 'methodology', 'type'];
        const questions_raw = removeKeysFromObject(builderFormValues, keysToRemove);
        const questions_data = convertQuestionJsonToArray(questions_raw);
        const question_data_with_ans_variable = addAnsVariable(questions_data);

        const questionsSchema = {
            survey_name: builderFormValues.survey_name,
            description: builderFormValues.description,
            methodology: builderFormValues.methodology,
            type: builderFormValues.type,
            questions_data: question_data_with_ans_variable
        }
        setSchemaJson(JSON.stringify(questionsSchema, null, 4));

    },[builderFormValues])


    return (
        <div className={styles["questionnaire-form-previewer"]}>
            <pre>{schemaJson}</pre>
        </div>
    );
};

export default QuestionnaireSchemaPreviewer;