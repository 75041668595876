import {
    WppCheckbox,
    WppTypography,
} from "@platform-ui-kit/components-library-react";
import React from "react";
import {STEP_CONTENT} from "./MetaDagConstants";

type Props = {
    checked: boolean;
    onChange: (value: boolean) => void;
};

export const DownloadSpecFileStep = (props: Props) => {
    const handleCheckboxChange = (event: CustomEvent) => {
        props.onChange(event.detail.checked);
    };

    return (
        <WppTypography type="l-body">
            {STEP_CONTENT.INPUT_FILE.DOWNLOAD_SPEC_FILE.description}
            <div style={{marginBottom: `1rem`}}>
                <a
                    href={process.env.REACT_APP_METADATA_INGESTION_DOWNLOAD_LINK}
                    target="_blank"
                >
                    Download Link
                </a>
            </div>
            <div>
                <WppCheckbox
                    required={true}
                    checked={props.checked}
                    indeterminate={false}
                    labelConfig={{
                        text: STEP_CONTENT.INPUT_FILE.DOWNLOAD_SPEC_FILE.checkboxText,
                    }}
                    onWppChange={handleCheckboxChange}
                />
            </div>
        </WppTypography>
    );
};
