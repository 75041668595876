import React, { useState, useEffect } from 'react';
import {
  WppStepper,
  WppStep,
  WppTopbar,
  WppButton,
  WppTypography,
} from '@platform-ui-kit/components-library-react';
import { useNavigate } from 'react-router-dom';
import styles from '../../components/DataProcessing/Dashboard/data-ingestion-workflow.module.scss';
import { InstructionStep } from './InstructionStep';
import { SurveySelectStep } from './SurveySelectStep';
import { ResultFolder } from './ResultFolder';
import { UploadFilesStep } from '../../components/DataProcessing/Common/UploadFilesStep';
import { WeightDagConstants } from '../../components/DataProcessing/WeightVariableIngestion/WeightDagConstants';
import { TriggerDag } from './ResultFoldercopy';
import { AppConfig } from '../../AppConfig';
import { useCookies } from 'react-cookie';
import axios from '../../api/axios-allure-docker';
import { AxiosRequestConfig } from 'axios';

const WORKFLOW_STEPS = 5;

export const AllureDockerFilesWorkflow: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [highestStep, setHighestStep] = useState(1);
  const [isDagSuccess, setIsDagSuccess] = useState(false);
  const [resultFolderError, setResultFolderError] = useState<string>('');
  const [testSuite, setTestSuite] = useState<string>('');
  const [folderValidation, setFolderValidation] = useState<string>('');
  const [selectionPanel, setSelectionPanel] = useState<string>('');
  const [fileNamingConventionStepChecked, setFileNamingConventionStepChecked] = useState<boolean>(false);
  const [isFileNamingConventionStepPristine, setIsFileNamingConventionStepPristine] = useState<boolean>(true);
  const [uploadFilesStepChecked, setUploadFilesStepChecked] = useState<boolean>(false);
  const [isUploadFilesStepPristine, setUploadFilesStepPristine] = useState<boolean>(true);
  const [surveySelectError, setSurveySelectError] = useState<string>('');
  const [isResultFolderValid, setIsResultFolderValid] = useState<boolean>(true); // New state
  const [valuefromapi, setValuefromapi] = useState('');

  

  const [cookies] = useCookies(['csrf_access_token']); // Use react-cookie to manage cook


  const handleNextStep = async () => {
    if (currentStep === WORKFLOW_STEPS) {
      return;
    }
  
    if (currentStep === 1 && !fileNamingConventionStepChecked) {
      setIsFileNamingConventionStepPristine(false);
      return;
    }
  
    if (currentStep === 2) {
      // Make the API call to validate the Result Folder before checking the conditions
      const csrfToken = cookies.csrf_access_token;
      const accessToken = localStorage.getItem('access_token');
      const projectConfig = {
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken || '',
          "Authorization": `Bearer ${accessToken}`
        },
        withCredentials: true
      };
  
      try {
        const response = await axios.post(
          `${AppConfig.alluerDockerApiUrl}/projects`,
          { id: testSuite },
          projectConfig
        );
        const message = response.data.meta_data?.message || '';
        // if (!message) {
        //     setResultFolderError('Received an empty response from the server.');
        //     setValuefromapi('3')
        //     return; // Prevent proceeding to the next step
        //   }
  
        // Set valuefromapi based on the response
        if (message.includes('project_id') && message.includes('not alphanumeric lowercase')) {
          setValuefromapi('1');
          return; // Prevent proceeding to the next step
        } else {
          setResultFolderError(''); // Clear error if valid
        }
      } catch (error) {
        if (error.message.includes('id') && error.message.includes(' alphanumeric lowercase')) {
            setResultFolderError("'id' should contains alphanumeric lowercase characters or hyphens. For example: 'my-project-id'")
            setValuefromapi ('1');
        } else if (error.message.includes(' 100 characters')) {
            setResultFolderError('should not contains more than 100 characters');
            setValuefromapi ('2');  
        } else if (error.message.includes('default')){
            setValuefromapi('3')}
        
        else if (error.message.includes ('existent')) {
        
            setValuefromapi('4'); // Handle any other cases
        }
        else if (error.message.includes ('empty')) {
        
            setValuefromapi('0'); // Handle any other cases
        }
        else {
            setValuefromapi('')
        }

} 
    
    }
    // After the API call, check conditions based on the value from API
    if (currentStep === 2) {
if (!testSuite.trim() || folderValidation !=='Name Available' ||  valuefromapi =='0') {
          setResultFolderError('id should not be empty');
          return;
  }
  else  if (!testSuite.trim() || folderValidation !=='Name Available' ||  valuefromapi =='3') {
    setResultFolderError("The id 'default' is not allowed. Try with another project_id");

    return;
}
else  if (!testSuite.trim() || folderValidation !=='Name Available' ||  valuefromapi =='1') {
    setResultFolderError("'id' should contains alphanumeric lowercase characters or hyphens. For example: 'my-project-id'")

    return;
}else  if (!testSuite.trim() || folderValidation !=='Name Available' ||  valuefromapi =='2') {
    setResultFolderError('should not contains more than 100 characters');

    return;
}else if (!testSuite.trim() || folderValidation !=='Name Available' ||  valuefromapi =='4') {
    setResultFolderError("project_id '{}' is existent");

    return;
}
 else {
    setResultFolderError('');
}
    }
  
    if (currentStep === 3) {
      if (selectionPanel.trim() === '') {
        setSurveySelectError('Please select a value for the SurveySelect.');
        return;
      } else if (selectionPanel === 'db_checks' || selectionPanel === 'global_view_checks') {
        setSurveySelectError('');
        setCurrentStep(5);
        return;
      }
    }
  
    if (currentStep === 4 && !uploadFilesStepChecked) {
      setUploadFilesStepPristine(false);
      return;
    }
  
    // Proceed to the next step
    setCurrentStep((prevStep) => {
      const step = prevStep + 1;
      if (step > highestStep) {
        setHighestStep(step);
      }
      return step;
    });
  };
  

  const handlePreviousStep = () => {
    if (currentStep === 1) return;

    if (currentStep === 5 && (selectionPanel === 'db_checks' || selectionPanel === 'global_view_checks')) {
      setCurrentStep(currentStep - 2);
      return;
    }

    setCurrentStep((prevStep) => prevStep - 1);
  };

  const onDagSuccess = () => {
    setIsDagSuccess(true);
  };

  const handleInputChange = (value: string) => {
    setTestSuite(value);
    //setFolderValidation(value);
  };

  const handleSurveyChange = (value: string) => {
    setSelectionPanel(value);
  };

 


  

  const getSelectedPage = () => {
    switch (currentStep) {
      case 1:
        return (
          <InstructionStep
            checked={fileNamingConventionStepChecked}
            onChange={setFileNamingConventionStepChecked}
            title="Please read the instructions below to proceed with the Data Quality Check Panel:"
            links={[
              {
                label: 'Confluence Wiki',
                link: 'https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337351712940/Audience+Origin+Data+Quality+Automation',
                context: 'Find more details here',
              },
            ]}
            context={['This is used for Data Quality Checks.']}
            checkboxText="I have read and understood the instructions."
          />
        );
      case 2:
        return (
          <ResultFolder
            value={testSuite}
            folderValidation={folderValidation}
            setfolderValidation={setFolderValidation}
            handleInputChange={handleInputChange}
            errorMessage={resultFolderError}
            
          />
        );
      case 3:
        return (
          <SurveySelectStep
            value={selectionPanel}
            onValueChange={handleSurveyChange}
            errorMessage={surveySelectError}
          />
        );
      case 4:
        return (
          <UploadFilesStep
            checked={uploadFilesStepChecked}
            onChange={setUploadFilesStepChecked}
            title="Upload SPSS Files for weight market file calculation:"
            links={[
              {
                label: 'Confluence Wiki',
                link: WeightDagConstants.weightMarketFileCalculationWikiLink,
                context: 'Find detailed instruction for weight market file calculation here',
              },
              {
                label: 'GCS Locations',
                link: `${AppConfig.ingestionBucketUrl}/qa/output_files`,
                context: 'Clean folder and upload prepared spss files here',
              },
            ]}
            checkboxText="I have uploaded the config files."
          />
        );
      case 5:
        return (
          <TriggerDag
            testSuite={selectionPanel}
            projectId={testSuite}
            onDagSuccess={onDagSuccess}
          />
        );
      default:
        return <div><h3>Page {currentStep}</h3></div>;
    }
  };

  const handleStepClick = (event: CustomEvent) => {
    if (isDagSuccess) return;

    const step = event.detail.index;
    if (step <= highestStep) {
      setCurrentStep(step);
    }
  };

  const navigateToMIHome = () => {
    navigate('/qa-home/qa-report-ui');
  };

  return (
    <div>
      <WppTopbar navigation={[]} style={{ margin: '1rem' }}>
        <div slot="app">
          <WppTypography type="l-midi" style={{ width: '20rem' }}>
            Data Quality Check Panel
          </WppTypography>
        </div>
      </WppTopbar>
      <div style={{ display: 'flex', margin: '2rem' }}>
        <WppStepper activeStep={currentStep} style={{ width: '19rem' }}>
          <WppStep
            onWppStepChange={handleStepClick}
            error={!fileNamingConventionStepChecked && !isFileNamingConventionStepPristine}
          >
            <p slot="label">Instructions</p>
          </WppStep>
          <WppStep onWppStepChange={handleStepClick}>
            <p slot="label">Result Folder</p>
          </WppStep>
          <WppStep onWppStepChange={handleStepClick}>
            <p slot="label">Test Suite</p>
          </WppStep>
          {selectionPanel === 'db_file_checks' && (
            <WppStep
              onWppStepChange={handleStepClick}
              error={!uploadFilesStepChecked && !isUploadFilesStepPristine}
            >
              <p slot="label">Upload Files</p>
            </WppStep>
          )}
          <WppStep onWppStepChange={handleStepClick}>
            <p slot="label">Trigger DAG</p>
          </WppStep>
        </WppStepper>
        <div className={styles['stepper-container']}>{getSelectedPage()}</div>
      </div>

      <div style={{ display: 'flex', margin: '2rem', justifyContent: 'flex-end' }}>
        {isDagSuccess ? (
          <WppButton variant="secondary" onClick={navigateToMIHome}>
            Go To Data Quality Check Panel Home
          </WppButton>
        ) : (
          <>
            <WppButton
              variant="secondary"
              onClick={handlePreviousStep}
              disabled={isDagSuccess}
              style={{ marginRight: '1rem' }}
            >
              Previous Step
            </WppButton>
            <WppButton onClick={handleNextStep} disabled={isDagSuccess}>
              Next Step
            </WppButton>
          </>
        )}
      </div>
    </div>
  );
};
