import {AppConfig, getIngestionGCSPrefix} from "../../../AppConfig";

export const RawDagConstants = {
    rawWikiLink: "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337144422440/AO+fact+data+pipeline",
    rawDataUploadUrl:
        getIngestionGCSPrefix(AppConfig.environment, "raw"),
    TriggerRawIngestionDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_etl_v4-0-2_snowflake",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_etl_v4-0-2_snowflake/graph`,
        defaultInfo: "This pipeline has no parameters, please make sure the spss files (per market per wave) you uploaded are valid and correct. Otherwise, the dag execution will fail."
    },
    ingestionFileNamingConvention: {
        label: "Verify file naming convention and format",
        instructions: [
            {
                key: "File naming Convention:",
                description: "AO{year}{wave}_{market_code}_{optional_extra_info}.sav"
            },
            {
                key: "Example:",
                description: "AO21W1_AE_V2_20211109.sav",
                variables: [
                    {
                        key: "{year}",
                        description: "2 digit year like 21, 22"
                    },
                    {
                        key: "{wave}",
                        description: "Wave label like W1, W2, OFFLINEW1"
                    },
                    {
                        key: "{market_code}",
                        description: "Market(Country code) like US, AU"
                    },
                    {
                        key: "{optional_extra_info}",
                        description: "Any optional information user wants to include like datetime"
                    }
                ]
            }
        ],
        validation: {
            checkbox: true,
            label: "File names updated successfully"
        }
    }
}
