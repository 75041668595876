import React, { useState } from 'react';
import styles from "../../../../src/questionnarie/QuestionnaireHome/questionnarie.module.css";
//import styles from '../../../../../src/questionnarie/QuestionnarieHome/questionnarie.module.css';
import {
  WppIconDownload,
  WppActionButton,
} from "@platform-ui-kit/components-library-react";
import { useQuery } from '@tanstack/react-query';
import {AudienceOriginRequest, EndPoints} from "../../../api/audience-origin";
import { useError } from "./../../../providers/ErrorContext"
import ErrorMessageComponent from './ErrorMessageComponent';

// FetchDownloadData function
const FetchDownloadData = async ({ surveyName, fileName }) => {
  const { data } = await AudienceOriginRequest.post(
    `${EndPoints.DownloadedFiles}`,
    {"fileName":fileName,
    "survey":surveyName},{ responseType: 'arraybuffer' }
  );
  return data;
};

// DownloadButtonComponent component
const weighDownloadButtonComponent = ({ event }) => {
  const [downloadInfo, setDownloadInfo] = useState(null);
  const { setError, clearError } = useError();

  const onDownloadClick = async (event) => {
    const surveyName = event.target.closest('.ag-row').childNodes[0].textContent;
    const fileName = event.target.closest('.ag-row').childNodes[1].textContent;

    setDownloadInfo({ surveyName, fileName });

    // Fetch the data
    try {
    const data = await FetchDownloadData({ surveyName, fileName });

    if (!data) {
      console.error('download failed :no data received');
      return;

    }
    
    var dataValue;
    var mimeType;
    if (fileName.includes('yaml')) {
      dataValue = data;
      mimeType = 'text/plain;charset=utf-8;';
    } else {
      dataValue = data;
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    // Create a downloadable link
    const blob = new Blob([dataValue], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Download error:', error);
    const errorMessage = error.message +" "+error.response.statusText || 'An unknown error occurred';
    setError(errorMessage);
  }
};

const convertToCSV = (data) => {
  const array = [Object.keys(data[0])].concat(data);
  return array.map(row => Object.values(row).join(',')).join('\n');
};

  return (
    <div slot="actions" className={styles['card-download']}>
     {/* <ErrorMessageComponent/> */}
      <WppActionButton
        onClick={onDownloadClick}
        variant="secondary"
      >
        <WppIconDownload slot="icon-start" />
      </WppActionButton>
    </div>
  );
};

export default weighDownloadButtonComponent;