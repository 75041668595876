import allureDockerAxios from './axios-allure-docker';
import { redirect, redirectRoot } from "../utility/navigate";
import { Cookies } from "react-cookie";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';



const apiHelper = (baseURL: string): AxiosInstance => {

    const request:  AxiosInstance = axios.create({
        baseURL: baseURL
    });

    const cookies = new Cookies();
    const logoutUser = () => {
        allureDockerAxios
            .delete("/logout")
            .then((response) => {
                localStorage.removeItem("expirationDate");
                localStorage.removeItem("roles");
                localStorage.removeItem("access_token");

                const csrf = cookies.get("csrf_refresh_token");
                const config = {
                    headers: {
                        "X-CSRF-TOKEN": csrf,
                    },
                };

                allureDockerAxios
                    .delete("/logout-refresh-token", config)
                    .then((response) => {
                        redirectRoot();
                    })
                    .catch((error) => {
                        cookies.remove("csrf_access_token", {path: "/"});
                        cookies.remove("csrf_refresh_token", {path: "/"});
                        redirect(error);
                    });
            })
            .catch((error) => {
                redirect(error);
            });
    }


    request.interceptors.request.use(
        function (config) {
            if (!config.headers["Authorization"]) {
                const accessToken = localStorage.getItem('access_token');
                if (accessToken) {
                    config.headers["Authorization"] = accessToken;
                }
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

// Add a response interceptor
    request.interceptors.response.use(function (response) {
        console.log('response error handler', response);
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        if (error?.response?.status === 401) {
            logoutUser();
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });

   return request;
}

export default apiHelper;

