import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import {
  WppIconButton,
  WppIconAdd,
  WppIconNavigationMenu,
} from "@platform-ui-kit/components-library-react";
import AllureDockerProjectsSideDrawer from "../AllureDockerProjectsSideDrawer/AllureDockerProjectsSideDrawer";
import AllureDockerNewProjectDialog from "../AllureDockerNewProjectDialog/AllureDockerNewProjectDialog";
import axios from "../../api/axios-allure-docker";
import { useHistory } from "react-router-dom";
import { redirect } from "../../utility/navigate";

import {useNavigate} from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  overflow: "auto",
  flexDirection: "column",
  height: 850,
  margin: "2rem",
}));

const AllureDockerHomePage = () => {
  const navigate = useNavigate();
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectSelected, setProjectSelected] = useState(null);
  const [shouldShowNewProjectDialog, setShouldShowNewProjectDialog] = useState(false);
  const [apiAlert, setApiAlert] = useState({
    severity: "info",
    show: false,
    message: "",
    duration: 0,
  });
  


  useEffect(() => {
    getProjects();
  }, []);

  const selectProject = (projectId) => {
    setProjectSelected(projectId);
  };

  const closeNewProjectDialog = () => {
    setShouldShowNewProjectDialog(false);
  };

  const openNewProjectDialog = () => {
   navigate('project1');
    setShouldShowNewProjectDialog(true);
  };

  const getProjects = () => {
    axios
      .get("/projects")
      .then((response) => {
        const projects = response.data.data.projects;
        setProjects(projects);
        setProjectSelected(null);
        if (projects.length !== 0) {
          selectProject(Object.keys(projects)[0]);
        }
      })
      .catch((error) => {
        redirect(error);
      });
  };

  const handleSideDrawerClose = () => {
    setOpenSideDrawer(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <StyledPaper>
        <div
          style={{
            margin: `1rem 1rem 0 1rem`,
            display: `flex`,
            justifyContent: `space-between`,
          }}
        >
          <WppIconButton
            size="m"
            onClick={() => setOpenSideDrawer(true)}
          >
            <WppIconNavigationMenu />
          </WppIconButton>

          <WppIconButton size="m" onClick={openNewProjectDialog}>
            <WppIconAdd />
          </WppIconButton>
        </div>
        <div
          style={{
            margin: `1rem 16rem`,
          }}
        >
          <div>
            <Typography
              style={{ marginTop: "30px" }}
              variant="h3"
              color="textSecondary"
              align="center"
            >
              Welcome to Audience Origin Test Report UI.
            </Typography>
            <Typography
              style={{ marginTop: "4rem" }}
              variant="h4"
              color="textSecondary"
              align="left"
            >
              AO data quality / QA automation framework consists of three components:
            </Typography>
            <Typography
              style={{ marginLeft: "40px", marginTop: "20px" }}
              variant="h6"
              color="textSecondary"
            >
              1. QA airflow pipeline: to run test suites to check ao data in files or databases. A Docker contains a QA framework based on pytest is sitting inside which allows the team to add and define new automated test cases easily
            </Typography>
            <Typography
              style={{ marginLeft: "40px" }}
              variant="h6"
              color="textSecondary"
            >
              2. QA API(service): a set of endpoints to accept/display test results sent from QA airflow pipeline
            </Typography>
            <Typography
              style={{ marginLeft: "40px" }}
              variant="h6"
              color="textSecondary"
            >
              3. QA UI: a UI on top of the QA API/service, to allow viewers to check the displayed data quality results and admins to manage test suites(projects)
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Typography
              style={{ marginTop: "20px" }}
              variant="h4"
              color="textSecondary"
              align="left"
            >
              To run a test suite:
            </Typography>
            <Typography
              style={{ marginLeft: "40px", marginTop: "20px" }}
              variant="h6"
              color="textSecondary"
            >
              1. go to the QA airflow pipeline, define configurations then trigger the pipeline.
            </Typography>
            <Typography
              style={{ marginLeft: "40px" }}
              variant="h6"
              color="textSecondary"
            >
              2. wait for the slack notification and go to the data quality website to check the report
            </Typography>
          </div>

          <div>
            <Typography
              style={{ marginTop: "2rem" }}
              variant="h4"
              color="textSecondary"
              align="left"
            >
              To add a new test / test suite:
            </Typography>
            <Typography
              style={{ marginLeft: "40px", marginTop: "20px" }}
              variant="h6"
              color="textSecondary"
            >
              1. simple logic, use helper code and existing test cases examples in{" "}
              <a
                href="https://gitlab.com/2sixty/audience-origins/audience-origin-qa/-/tree/develop/test"
                target="_blank"
              >
                {" "}
                here
              </a>
              , to add new tests, create a PR.
            </Typography>
            <Typography
              style={{ marginLeft: "40px" }}
              variant="h6"
              color="textSecondary"
            >
              2. complicated logic or not sure how to add a test case, create a Jupyter notebook version python script and ask the dev team to convert it to a test case for you,{" "}
              <a
                href=" https://gitlab.com/2sixty/audience-origins/audience-origin-qa/-/tree/develop/notebooks"
                target="_blank"
              >
                click here
              </a>
            </Typography>
            <Typography
              style={{ marginLeft: "40px" }}
              variant="h6"
              color="textSecondary"
            >
              3. once PR is approved and merged to develop, grab the docker image tag in the CICD pipeline, put it in airflow config variables, and trigger the pipeline to check.
            </Typography>
          </div>
        </div>
      </StyledPaper>

      <AllureDockerProjectsSideDrawer
        title="Allure Docker Service UI"
        projects={projects}
        isSideDrawerOpen={openSideDrawer}
        handleSideDrawerClose={handleSideDrawerClose}
        selectProject={selectProject}
      />

      <AllureDockerNewProjectDialog
        open={shouldShowNewProjectDialog}
        closeNewProjectDialog={closeNewProjectDialog}
        getProjects={getProjects}
      />
    </React.Fragment>
  );
};

export default AllureDockerHomePage;
