import { WppBreadcrumb } from '@platform-ui-kit/components-library-react';
import { useNavigate } from 'react-router-dom';


export const BreadCrumb = (props) => {
  const navigate = useNavigate();

  const handleRouteChange = (event) => {
    //const navigate = useNavigate();

    navigate(event.detail.path);
  };

  return (
    <WppBreadcrumb style={{display:'flex', marginLeft: '2rem' , marginBottom: '2rem'}} items={props.items} middleTruncation onWppChange={handleRouteChange} ></WppBreadcrumb>
  );
};