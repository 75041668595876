import React from 'react';

import QuestionnairePreviewer from "../QuestionnairePreviewer/QuestionnairePreviewer";


const QuestionnairePreviewerWidget = () => {
    return (
        <div>
            <QuestionnairePreviewer></QuestionnairePreviewer>
        </div>
    );
}

export default QuestionnairePreviewerWidget;