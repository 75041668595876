import {AppConfig} from "../../../AppConfig";

export const ExportDagsConstants = {
    marketExportWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337292271688/Audience+Origin+Export",
    marketsBatchExportWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337559920862/Audience+Origin+Trigger+Export",
    globalExportWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337560379400/Audience+Origin+Global+Data",
    globalDecodedExportWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337951498475/Audience+Origin+Decode+Global+Export",
    globalSpssWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337679163511/Audience+Origin+Global+table+export+in+SPSS+format",
    triggerExportMarketDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_snowflake_export",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_snowflake_export/graph`,
        inputFields: [
            {
                fieldId: "snowflake_schema",
                fieldLabel: "Snowflake Schema",
                fieldType: "string",
                tooltip: "the snowflake schema to export, e.g. AO_EXPORT_SCHEMA",
                defaultValue: "AO_EXPORT_SCHEMA"
            },
            {
                fieldId: "core_table_prefix",
                fieldLabel: "Core Table Prefix",
                fieldType: "string",
                tooltip: "the core table prefix to export, e.g. AO_CORE_TEST_",
                defaultValue: "AO_CORE_"

            },
            {
                fieldId: "meta_table_prefix",
                fieldLabel: "Meta Table Prefix",
                fieldType: "string",
                tooltip: "the meta table prefix to export, e.g. AO_METADATA_TEST_",
                defaultValue: "AO_METADATA_"

            },
            {
                fieldId: "is_ascription",
                fieldLabel: "Use  Ascription: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "whether to use ascription table for export"
            },
            {
                fieldId: "survey_market",
                fieldLabel: "Market ",
                fieldType: "string",
                tooltip: "the single market to export, e.g. AU"
            },
            {
                fieldId: "survey_version",
                fieldLabel: "Version ",
                fieldType: "string",
                tooltip: "the version to export, e.g. 2X",
                defaultValue: "2X"
            },
            {
                fieldId: "survey_wave",
                fieldLabel: "Wave Label",
                fieldType: "string",
                tooltip: "the wave label to export, e.g. OFFLINEW1, W2(for online)"
            },
            {
                fieldId: "calculate_projections",
                fieldLabel: "Calculate Projections: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to calculate projections"
            },
            {
                fieldId: "only_metadata",
                fieldLabel: "Export only Metadata: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export only metadata"
            },
            {
                fieldId: "internal_export",
                fieldLabel: "Export to internal schema: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export to internal schema"
            },
            {
                fieldId: "report_type",
                fieldLabel: "Report Type",
                fieldType: "string",
                tooltip: "e.g. OFFLINE, ONLINE"
            }
        ]
    },

    triggerExportGlobalDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_global_data",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_global_data/graph`,
        inputFields: [
            {
                fieldId: "global_core_table_name",
                fieldLabel: "Core Table Prefix",
                fieldType: "string",
                tooltip: "the core table prefix to export, e.g. AO_CORE_TEST_",
                defaultValue: "AO_CORE_"

            },
            {
                fieldId: "global_meta_table_name",
                fieldLabel: "Meta Table Prefix",
                fieldType: "string",
                tooltip: "the meta table prefix to export, e.g. AO_METADATA_TEST_",
                defaultValue: "AO_METADATA_"

            },
            {
                fieldId: "market_ids",
                fieldLabel: "Markets:",
                fieldType: "array",
                tooltip: "Markets to export, e.g. AU, BE, AR"
            },
            {
                fieldId: "version",
                fieldLabel: "Version: ",
                fieldType: "string",
                tooltip: "Version to export(check ao_year_wave_mapping table for version info)"
            },
            {
                fieldId: "calculate_projections",
                fieldLabel: "Calculate Projections: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to calculate projections"
            },
            {
                fieldId: "only_metadata",
                fieldLabel: "Export only Metadata: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export only metadata"
            },
            {
                fieldId: "report_type",
                fieldLabel: "Report Type",
                fieldType: "string",
                tooltip: "e.g. OFFLINE, ONLINE"
            }
        ]
    },

    triggerBatchExportDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_batch_trigger_snowflake_export",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_batch_trigger_snowflake_export/graph`,
        inputFields: [
            {
                fieldId: "is_ascription",
                fieldLabel: "Use  Ascription: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to use ascription table for export"
            },
            {
                fieldId: "markets",
                fieldLabel: "Surveys ",
                fieldType: "array",
                tooltip: "Surveys to export (e.g.SG-2X-W1, SG-1X-OFFLINEW1, if ascription is checked, wave label part will be ignored. e.g. it takes SG-2X from SG-2X-W1)"
            },
            {
                fieldId: "calculate_projections",
                fieldLabel: "Calculate Projections: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to calculate projections"
            },
            {
                fieldId: "only_metadata",
                fieldLabel: "Export only Metadata: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export only metadata"
            },
            {
                fieldId: "internal_export",
                fieldLabel: "Export to internal schema: ",
                fieldType: "boolean",
                defaultValue: false,
                tooltip: "Whether to export to internal schema"
            },
            {
                fieldId: "report_type",
                fieldLabel: "Report Type",
                fieldType: "string",
                tooltip: "e.g. OFFLINE, ONLINE"
            }
        ]
    },
    triggerExportGlobalDecodedDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_global_decoded_data",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_global_decoded_data/graph`,
        inputFields: [
            {
                fieldId: "market_ids",
                fieldLabel: "Markets: ",
                fieldType: "array",
                tooltip: "Markets to export, e.g. AU, BE, AR"
            },
            {
                fieldId: "version",
                fieldLabel: "Version: ",
                fieldType: "string",
                tooltip: "Version to export(check ao_year_wave_mapping table for version info)"
            },
            {
                fieldId: "calculate_projections",
                fieldLabel: "Calculate Projections: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to calculate projections"
            },
            {
                fieldId: "report_type",
                fieldLabel: "Report Type",
                fieldType: "string",
                tooltip: "e.g. OFFLINE, ONLINE"
            },
        ]
    },
    triggerSpssDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_global_spss_export",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_global_spss_export/graph`,
        inputFields: [
            {
                fieldId: "schema_name",
                fieldLabel: "Export Schema Name: ",
                fieldType: "string",
                tooltip: "Schema name of the table that needs to be exported, e.g. AO_EXPORT_SCHEMA_WITH_IDS",
                defaultValue: "AO_EXPORT_SCHEMA_WITH_IDS"
            },
            {
                fieldId: "table_name",
                fieldLabel: "Export Table Name: ",
                fieldType: "string",
                tooltip: "table name that needs to be exported, e.g. AO_CORE_1X_GLOBAL"
            },
            {
                fieldId: "gcs_base_path",
                fieldLabel: "Export GCS Location: ",
                fieldType: "string",
                defaultValue: "pipelines/ao_global_spss_export",
                tooltip: "the GCS location where the spss files will be exported"
            }
        ]
    },
}
