import React from "react";
import { useDrop } from "react-dnd";
import GenericQuestion from "../QuestionComponents/GenericQuestion";
import styles from "./QuestionnaireBuilder.module.css";
import { QuestionsMetaDnd } from "../../types";
import { ReactComponent as PreviewAnimation1 } from "../../assets/images/preview_animation1.svg";
import { ReactComponent as PreviewAnimation2 } from "../../assets/images/preview_animation2.svg";

type QuestionsPanelProps = {
  handleMouseEnter: (e) => void;
  handleQuestionDrop: (type: string) => void;
  handleQuestionDelete: (uuid: string) => void;
  handleClearField: (
    uuid: string,
    index: number,
    field: string,
    itemIndex: number
  ) => void;
  handleChange: (
    uuid: string,
    index: number,
    field: string,
    itemIndex: number,
    value: string
  ) => void;
  questions: Array<QuestionsMetaDnd>;
  form: any;
};
export const QuestionsPanel: React.FC<QuestionsPanelProps> = (props) => {
  const [, drop] = useDrop(
    () => ({
      accept: ["ICON"],
      drop: (item: { itemType: string; type: string }) => {
        console.log("entering drop from left panel...");
        console.log("the question is");
        console.log(item.itemType);
        console.log(item.type);
        props.handleQuestionDrop(item.type);
      },
    }),
    [props.handleQuestionDrop]
  );

  const deleteCard = (uuid: string) => {
    props.handleQuestionDelete(uuid);
  };

  return (
    <div
      ref={drop}
      className={styles["right-panel"]}
      onMouseEnter={props.handleMouseEnter}
    >
      {props.questions && props.questions.length ? (
        props.questions.map((question, index) => (
          <div key={index}>
            <GenericQuestion
              key={question.uuid}
              order={index}
              id={question.uuid}
              type={question.type}
              handleDelete={deleteCard}
              handleClearField={(v, itemIndex) =>
                props.handleClearField(question.uuid, index, v, itemIndex)
              }
              handleChange={(v, itemIndex, value) =>
                props.handleChange(question.uuid, index, v, itemIndex, value)
              }
              form={props.form}
            ></GenericQuestion>
          </div>
        ))
      ) : (
        <div className={styles["row"]}>
          <PreviewAnimation1 />
          <PreviewAnimation2 />
        </div>
      )}
    </div>
  );
};
