import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  WppCard,
  WppActionButton,
  WppGrid,
  WppIconMore,
  WppTypography,
} from "@platform-ui-kit/components-library-react";

const AllureDockerDashboard = () => {
  return (
    <WppGrid container rowSpacing={4}>
      <WppGrid item all={6}>
        <WppCard>
          <div slot="header">
            <WppTypography>Reports</WppTypography>
          </div>
          <div slot="actions">
            <WppActionButton variant="secondary">
              <WppIconMore slot="icon-start" direction="horizontal" />
            </WppActionButton>
          </div>
          <div
            style={{
              height: 100,
            }}
          >
            <Link to="/qa-report-ui">Go to reports</Link>
          </div>
        </WppCard>
      </WppGrid>
      <WppGrid item all={6}>
        <WppCard>
          <div slot="header">
            <WppTypography>More</WppTypography>
          </div>
          <div slot="actions">
            <WppActionButton variant="secondary">
              <WppIconMore slot="icon-start" direction="horizontal" />
            </WppActionButton>
          </div>
          <div
            style={{
              height: 100,
            }}
          >
            <Link to="/qa-report-ui">More</Link>
          </div>
        </WppCard>
      </WppGrid>
    </WppGrid>
  );
};
export default AllureDockerDashboard;
