import {useDrag, DragSourceMonitor, useDrop, DropTargetMonitor,} from "react-dnd";
import React, {useRef} from "react";
import styles from "../QuestionnaireBuilder/QuestionnaireBuilder.module.css";


type DraggableQuestionOutlineTileProps = {
    uuid: string;
    title: string;
    index: number;
    moveQuestion: (dragIndex: number, hoverIndex: number) => void;
};

export const DraggableQuestionOutlineTile: React.FC<DraggableQuestionOutlineTileProps> = ({ uuid, title, index, moveQuestion }) => {

    const ref = useRef<HTMLDivElement>(null);
    const [{ isDragging }, drag] = useDrag({
        type: 'TILE',
        item: { uuid, index },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'TILE',
        hover(item: { index: number }, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }

            const dragIndex = item.index;

            const hoverIndex = index;
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const clientOffset = monitor.getClientOffset();

            if (!hoverBoundingRect || !clientOffset) {
                return;
            }

            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveQuestion(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    drag(drop(ref));

    return (
        <div ref={ref} className={styles["out-line-tree-item"]} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <p > {index + 1}. {title}</p>
        </div>
    );
};