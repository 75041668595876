import {useNavigate, useParams} from "react-router";
import {AppConfig} from "../AppConfig";

export const redirect = (error) => {
    if (error.redirect) {
        window.location.reload();
    }
};

export const redirectRoot = () => {
    window.location.replace(AppConfig.routerBaseName);
};

export const refreshCurrentPage = () => {
    window.location.replace(window.location.href);
};

export const redirectRootInSeconds = (seconds) => {
    wait(seconds * 1000).then(() => {
        redirectRoot();
    });
};

export const refreshCurrentPageInSeconds = (seconds) => {
    wait(seconds * 1000).then(() => {
        refreshCurrentPage();
    });
};

export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const history = useNavigate();
        const params = useParams();
        props = {...props, match: {params}};
        return <Component history={history} {...props} params={params}/>;
    };
    return Wrapper;
};
