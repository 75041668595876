import React, {useRef, useState} from "react";
import {DateTime} from "luxon";
import {useQuery} from "@tanstack/react-query";
import {WppTypography} from "@platform-ui-kit/components-library-react";
import {AudienceOriginRequest, EndPoints} from "../../../api/audience-origin";
import {MetadataFileHistory} from "../../../types";
import {IngestionGrid} from "../IngestionGrid/IngestionGrid";
import {AG_GRID_CELL_STYLE} from "../../../constants/ag-grid-defaults";

function useMetadataFileHistory() {
    return useQuery({
        queryKey: ["metadataFileHistory"],
        queryFn: async () => {
            const {data} = await AudienceOriginRequest.post<MetadataFileHistory[]>(
                EndPoints.MetadataFileHistory
            );
            return data;
        },
    });
}

const columnDefs: any[] = [
    {
        headerName: "Survey",
        field: "survey",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 200,
    },
    {
        headerName: "Dictionary File",
        field: "dictionary_file",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    },
    {
        headerName: "Spec File",
        field: "spec_file",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    },
    // {
    //   headerName: "Input Files",
    //   field: "input_files",
    //   sortable: true,
    //   cellStyle: AG_GRID_CELL_STYLE,
    // },
    {
        headerName: "Uploaded Time",
        field: "load_time",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 160,
        cellRenderer: ({data}) => (
            <> {DateTime.fromISO(data.load_time).toLocaleString()}</>
        ),
    },
    {
        headerName: "Status",
        field: "status",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
        maxWidth: 160,
    },
    {
        headerName: "Info",
        field: "error_info",
        sortable: true,
        cellStyle: AG_GRID_CELL_STYLE,
    },
];

const MetadataFileHistoryGrid = () => {
    const {status, data, error, isFetching} = useMetadataFileHistory();

    return (
        <div style={{marginTop: "1rem"}}>
            <WppTypography
                type="l-body"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: `0.5rem 0`,
                }}
            >
                Last Loaded Metadata Files
            </WppTypography>
            <IngestionGrid columns={columnDefs} data={data} status={status}/>
        </div>
    );
};

export default MetadataFileHistoryGrid;
