import React, { useEffect } from 'react';
import { WppInlineMessage } from "@platform-ui-kit/components-library-react";
import { useError } from './../../../providers/ErrorContext';

const ErrorMessageComponent = () => {
  const { errorMessage, clearError } = useError();

  useEffect(() => {
    console.log(errorMessage);
    if (errorMessage) {
      const timer = setTimeout(() => {
        clearError();
      }, 5000); // Hide message after 5 seconds

      return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts or errorMessage changes
    }
  }, [errorMessage, clearError]);

  return (
    <div>
      {errorMessage && (
        <WppInlineMessage
          style={{ position: 'fixed', top: "8%", width: '35%', right: '0rem', zIndex: 1000 }}
          size="m"
          message={errorMessage}
          type="error"
        />
      )}
    </div>
  );
};

export default ErrorMessageComponent;
