import React, { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AppTopbar } from "./AppTopbar";

import styles from "./dashboard.module.css";

const HomeLayout = () => {
  const mainContent = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (mainContent.current) {
      mainContent.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return (

    <div className={styles["dashboard-container"]}>
      <header className={styles["dashboard-header"]}>

        <AppTopbar />

      </header>
      
        <main ref={mainContent} className={styles["dashboard-content"]} id="main">

          <Outlet></Outlet>

        </main>
     
    </div>

  );
};

export default HomeLayout;

