
import QuestionType  from "../QuestionComponents/QuestionType";
export const removeKeysFromObject = (obj, keysToRemove) => {
  // Create a copy of the object
  const newObj = { ...obj };

  // Iterate over the keys to remove and delete them from the new object
  keysToRemove.forEach((key) => {
    delete newObj[key];
  });

  return newObj;
};

export const convertQuestionJsonToArray = (jsonObj) => {
  const result = [];
  Object.keys(jsonObj).forEach((key) => {
    const value = jsonObj[key];
    const newEntry = { ...value };
    if (newEntry.answers) {
      newEntry.answers = newEntry.answers.map((answer) => {
        const newAnswer = {};
        Object.keys(answer).forEach((answerKey) => {
          // Chop off the key prefix before '|', if it exists
          const newKey = answerKey.split("|").pop();
          newAnswer[newKey] = answer[answerKey];
        });
        return newAnswer;
      });
    }

    result.push(newEntry);
  });

  return result;
};

export const restructureValueData = (item, questionsOrder) => {
  const result = {};
  // console.log("change form value structure....")
  // console.log(questionsOrder);
  for (const key in item) {
    if (item.hasOwnProperty(key)) {
      const [prefix, ...rest] = key.split("|");
      const newKey = rest.join("|");
      if (rest.length === 0) {
        result[prefix] = item[key];
      } else {
        //console.log(rest);
        if (!result[prefix]) {
          result[prefix] = {};
        }
        //fix formily sequence input, it doesn't trigger form value update when re-ordering in react component
        // get final order based on uuid from the question list
        // if(newKey==="sequence"){
        //     const index = questionsOrder.get(prefix);
        //     //console.log("sequence right index....",prefix, index);
        //     result[prefix][newKey]=index;
        // }
        // else{
        result[prefix][newKey] = item[key];
        if (newKey == "type" && (item[key] == QuestionType.OpenText || item[key] == QuestionType.OpenNumeric)){
          //adding new entry for open numeric and open text
          result[prefix]["answers"][0]["ans_code"] = 10000;
          console.log(result[prefix]["answers"][0]["ans_code"]);
        }
        if (newKey == "type" &&  item[key] == QuestionType.GridNumeric ){
          //adding new
          const hasColumn = result[prefix]["answers"].some((ans) => ans.ans_type === "COLUMN");
          if (!hasColumn){
          result[prefix]["answers"].push({"ans_code": 10000, "ans_type":'COLUMN', "ans_sequence" :1});}
        }
      }
    }
  }
  return result;
};

export const cloneObjectWithArrays = (obj) => {
  // Create a shallow copy of the object
  const clone = { ...obj };

  // Iterate over the properties of the object
  Object.keys(clone).forEach((key) => {
    // If the property is an array, create a shallow copy of the array
    if (Array.isArray(clone[key])) {
      clone[key] = [...clone[key]];
    }
    // If the property is an object, recursively clone it
    else if (typeof clone[key] === "object" && clone[key] !== null) {
      clone[key] = cloneObjectWithArrays(clone[key]);
    }
  });

  return clone;
};

export const parseQuestionsMeta = (valuesObject) => {
  const result = [];
  //todo: check type of valuesObject
  const questionnaireMeta = new Set([
    "survey_name",
    "description",
    "methodology",
    "type",
  ]);

  Object.keys(valuesObject).forEach((key) => {
    const question = valuesObject[key];
    if (!questionnaireMeta.has(key)) {
      result.push({
        uuid: key,
        type: question.type,
        index: question.sequence,
        title: question.question_text,
      });
    }
  });

  return result;
};

export const removeItemsStartingWithArrayItems = (array, jsonObject) => {
  // Create a new object to avoid mutating the original jsonObject
  let newJsonObject = { ...jsonObject };
  if (array.length > 0) {
    // Iterate over each key in the jsonObject
    Object.keys(jsonObject).forEach((key) => {
      // Check if the key starts with any string in the array
      const includesString = array.some((str) => key.includes(str));
      if (includesString) {
        // If the key starts with a string in the array, remove it from the newJsonObject
        console.log("delete....");
        console.log(key);
        delete newJsonObject[key];
      }
    });
    // Return the modified jsonObject
    return newJsonObject;
  }
  return jsonObject;
};

export const updateSequencesFromMap = (map, jsonObject) => {
  let newJsonObject = { ...jsonObject };
  Object.keys(jsonObject).forEach((key) => {
    if (key.endsWith("|sequence")) {
      const uuid = key.split("|")[0];
      const mapValue = map.get(uuid);
      if (mapValue !== undefined) {
        newJsonObject[key] = mapValue;
      }
    }
  });

  let sortedJsonObject = sortJsonObjectBySequence(newJsonObject);

  return sortedJsonObject;
};

const sortJsonObjectBySequence = (jsonObject) => {
  // Splitting the object into two parts
  const withPipe = {};
  const withoutPipe = {};

  Object.keys(jsonObject).forEach((key) => {
    if (key.includes("|")) {
      withPipe[key] = jsonObject[key];
    } else {
      withoutPipe[key] = jsonObject[key];
    }
  });

  // Sorting the keys with pipe
  const sortedKeys = Object.keys(withPipe).sort((a, b) => {
    const sequenceA = parseInt(withPipe[a.split("|")[0] + "|sequence"]);
    const sequenceB = parseInt(withPipe[b.split("|")[0] + "|sequence"]);

    if (sequenceA !== sequenceB) {
      return sequenceA - sequenceB;
    }

    return a.localeCompare(b);
  });

  // Constructing the new sorted object
  const sortedObject = { ...withoutPipe };

  sortedKeys.forEach((key) => {
    sortedObject[key] = withPipe[key];
  });

  return sortedObject;
};

export const addAnsVariable = (data) => {
  data.forEach((item) => {
    const questionLabel = item.question_label;
    const isMultiCoded = item.type === "multi-coded";

    item.answers.forEach((answer) => {
      // If it's multi-coded, concatenate question_label and ans_code. Otherwise, just use question_label.
      answer.ans_variable =
        answer.ans_type == "ROW" ? `${questionLabel}_${answer.ans_code}` : "";
    });
  });

  return data;
};


export const findDuplicatesInQuestions = (questionsArray) => {
  // Object to keep track of question label occurrences
  const labelOccurrences = {};

  // Array to hold information about duplications found
  const duplicationsFound = [];

  // Iterate through the questions array
  questionsArray.forEach(question => {
    const label = question.question_label;
    const answers = question.answers;
    const answerCodes = new Set();

    // Check for duplicate question_label
    if (labelOccurrences[label]) {
      // If it's the first duplicate, note it
      labelOccurrences[label] += 1;
      duplicationsFound.push(`Duplicate question_label found: ${label};`);
    } else {
      labelOccurrences[label] = 1;
    }

    // Check for duplicate ans_code within the same question
    answers.forEach(answer => {
      const code = answer.ans_code;
      if (answerCodes.has(code)) {
        // Found a duplicate ans_code within the same question
        duplicationsFound.push(`Duplicate ans_code ${code} found in question_label: ${label}`);
      } else {
        answerCodes.add(code);
      }
    });
  });

  // Return the findings
  return duplicationsFound.length > 0 ? duplicationsFound : [];
}
