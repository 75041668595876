import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { withRouter } from "../../utility/navigate";

const drawerWidth = 240;
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
}));

const DivDrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  width: drawerWidth,
}));

const allureDockerProjectsSideDrawer = (props) => {
  const elements = [];
  for (let key in props.projects) {
    elements.push(
      <Link
        to={`/qa-home/projects/${key}`}
        key={key}
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        <ListItem
          button
          id={key}
          onClick={() => {
            props.selectProject(key);
            props.handleSideDrawerClose();
          }}
        >
          <ListItemText primary={key} />
        </ListItem>
      </Link>
    );
  }

  return (
    <StyledDrawer
      variant="persistent"
      anchor="left"
      open={props.isSideDrawerOpen}
    >
      <DivDrawerHeader>
        <IconButton onClick={props.handleSideDrawerClose} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </DivDrawerHeader>
      <Divider />
      <List>{elements}</List>
    </StyledDrawer>
  );
};

export default withRouter(allureDockerProjectsSideDrawer);
