import {AppConfig, getIngestionGCSPrefix} from "../../../AppConfig";

export const NetDagConstants = {
    TriggerNetMetadataIngestionDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_net_variable_metadata_v1",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_net_variable_metadata_v1/graph`,
        inputFields: [
            {
                fieldId: "year",
                fieldLabel: "Year: ",
                fieldType: "dropdown",
                defaultValue: "",
                tooltip: "2 digit year for survey, e.g. 22, 23, 24"
            },
            {
                fieldId: "wave_label",
                fieldLabel: "Wave Label: ",
                fieldType: "dropdown",
                defaultValue: "",
                tooltip: "Wave number W1,W2,W3,W4, OFFLINEW2 etc.."
            },
            {
                fieldId: "gcs_path_input_config_files",
                fieldLabel: "GCS Location of Net Config Files: ",
                fieldType: "string",
                defaultValue: "env:netConfigUploadUrl",
                tooltip: "GCS location where net config yml files are uploaded"
            }
        ]
    },
    TriggerNetTestRunCalculationDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_net_variable_etl_v4-0-3_snowflake_test_run",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_net_variable_etl_v4-0-3_snowflake_test_run/graph`,
        inputFields: [
            {
                fieldId: "year",
                fieldLabel: "Year: ",
                fieldType: "dropdown",
                tooltip: "2 digit year for survey, e.g. 22, 23, 24"
            },
            {
                fieldId: "wave_label",
                fieldLabel: "Wave Label: ",
                fieldType: "dropdown",
                tooltip: "Wave number W1,W2,W3,W4 etc.."
            },
            {
                fieldId: "markets",
                fieldLabel: "Markets ",
                fieldType: "dropdown",
                tooltip: "Market codes, you can specify multiple market codes separated by comma, e.g. US, AU"
            },
            {
                fieldId: "use_ascription",
                fieldLabel: "Use  Ascription: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Flag to decide whether to use fact data ingested in ascription table for net variable calculation"
            },
            {
                fieldId: "gcs_path_input_config_files",
                fieldLabel: "GCS Location of Net Config files ",
                fieldType: "string",
                defaultValue: getIngestionGCSPrefix(AppConfig.environment, "net_test_run_input"),
                tooltip: "GCS location where net config yml files are uploaded"
            },
            {
                fieldId: "gcs_path_output_files",
                fieldLabel: "GCS Location for Output Files: ",
                fieldType: "string",
                Editable: true,
                readonly: false,
                defaultValue: getIngestionGCSPrefix(AppConfig.environment, "net_test_run_output"),
                tooltip: "GCS location where output files will be stored"
                
            },
            {
                fieldId: "gcs_path_validation_files",
                fieldLabel: "GCS Location of Validation files ",
                fieldType: "string",
                defaultValue: getIngestionGCSPrefix(AppConfig.environment, "net_test_run_validation"),
                tooltip: "GCS location where generated validation files will be stored"
            },
            {
                fieldId: "gcs_path_report_files",
                fieldLabel: "GCS Location of Report files ",
                fieldType: "string",
                defaultValue: getIngestionGCSPrefix(AppConfig.environment, "net_test_run_reports"),
                tooltip: "GCS location where generated report files will be stored"
            },
            {
                fieldId: "output_file_format",
                fieldLabel: "Output File Format: ",
                fieldType: "dropdown",
                options: [
                    {label: "SPSS", "value": "spss"},
                    {label: "CSV", "value": "csv"}
                ],
                defaultValue: "spss",
                tooltip: "SPSS or CSV"
            },
            {
                fieldId: "export_columns_mode",
                fieldLabel: "Export Mode: ",
                fieldType: "dropdown",
                options: [
                    {
                        label: "Net Variables and Dependent Core Variables",
                        value: "net_and_dependencies"
                    },
                    {label: "Net Variables", "value": "net_only"},
                    {label: "All", "value": "all"}
                ],
                defaultValue: "net_and_dependencies",
                tooltip: "Columns(Variables) needed in output file"
            }
        ]
    },
    TriggerNetCalculationDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_net_variable_etl_v4-0-3_snowflake",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_net_variable_etl_v4-0-3_snowflake/graph`,
        inputFields: [
            {
                fieldId: "year",
                fieldLabel: "Year: ",
                fieldType: "dropdown",
                tooltip: "2 digit year for survey, e.g. 22, 23, 24"
            },
            {
                fieldId: "wave_label",
                fieldLabel: "Wave Label: ",
                fieldType: "dropdown",
                tooltip: "Wave number W1,W2,W3,W4 etc.."
            },
            {
                fieldId: "markets",
                fieldLabel: "Markets ",
                fieldType: "dropdown",
                tooltip: "Market codes, you can specify multiple market codes separated by comma, e.g. US, AU"
            },
            {
                fieldId: "use_ascription",
                fieldLabel: "Use  Ascription: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Whether to use fact data ingested in ascription table for net variable calculation"
            },
        ]
    },
    TriggerNetIngestionDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_net_variable_etl_v4-0-1_snowflake",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_net_variable_etl_v4-0-1_snowflake/graph`,
        defaultInfo: "This pipeline has no parameters, please make sure the spss files (per market per wave) you uploaded are valid and correct. Otherwise, the dag execution will fail."
    },
    triggerNetMetaFileDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_load_calculated_field",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_load_calculated_field/graph`,
        inputFields: [
            {
                fieldId: "year",
                fieldLabel: "Year: ",
                fieldType: "dropdown",
                tooltip: "2 digit year for survey, e.g. 22, 23, 24"
            },
            {
                fieldId: "wave_label",
                fieldLabel: "Wave Label: ",
                fieldType: "dropdown",
                tooltip: "Wave number W1,W2,W3,W4 etc.."
            },
            {
                fieldId: "markets",
                fieldLabel: "Markets ",
                fieldType: "dropdown",
                tooltip: "Market codes, you can specify multiple market codes separated by comma, e.g. US, AU"
            },
            {
                fieldId: "snowflake_schema",
                fieldLabel: "Snowflake Schema: ",
                fieldType: "string",
                tooltip: "Snowflake schema name",
                defaultValue: "AO_SCHEMA"
            }
        ]
    },

    netMetaFileWikiLink: "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22338022834183/AO+net+meta+file+upload+pipeline+deprecated",
    netMetaFiledUrl:
        getIngestionGCSPrefix(AppConfig.environment, "net_metadata"),
    netTestRunWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337488388258/SPSS+to+Python+NET+pipeline+v2.0#Variable-Definition-Format",
    netMetaRunWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337795620882/AO+Net+Metadata+Pipeline",
    netCalculationWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337549992138/Using+NETs+pipeline+SF-2-SF",
    netIngestionWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337144259086/AO+NET+variables+pipeline+v1.0",
    netDataUploadUrl:
        getIngestionGCSPrefix(AppConfig.environment, "net"),
    netConfigUploadUrl:
        getIngestionGCSPrefix(AppConfig.environment, "net_config"),
    netTestRunUploadUrl:
        getIngestionGCSPrefix(AppConfig.environment, "net_test_run_input"),
}
