import React from "react";
import {
  WppCard,
  WppButton,
  WppTypography,
} from "@platform-ui-kit/components-library-react";
import { Link } from "react-router-dom";
import styles from "./dashboard.module.css";

const DashboardCard = (props) => {
  return (
    <WppCard>
      <div slot="header" className={styles["dashboard-card-header"]}>
        <img height={300} src={props.image} className="image" />
        <WppTypography
          tag="h4"
          className={styles["dashboard-card-header-name"]}
        >
          {props.title}
        </WppTypography>
      </div>
      <div>
        <WppTypography>{props.description}</WppTypography>
      </div>
      <div className={styles["dashboard-card-footer"]}>
        <Link to={props.route}>
          <WppButton>Explore</WppButton>
        </Link>
      </div>
    </WppCard>
  );
};

export default DashboardCard;
