import Stock from "../assets/images/stock.jpeg";
import QAStock from "../assets/images/qa.jpg";
import StockDesign from "../assets/images/stock2.png";

export const DASHBOARD_CARDS = [
  {
    title: "Data Quality Check",
    image: QAStock,
    description: "Test suites to check data quality",
    route: "/qa-home/qa-report-ui",
  },
  {
    title: "Data Processing",
    image: Stock,
    description: "Dashboards and workflows to ingest and transform data",
    route: "/data-processing",
  },
  {
    title: "Questionnaire Design",
    image: StockDesign,
    description: "Templates and tools to design questionnaires",
    route: "/questionnaire-design",
  },
];
