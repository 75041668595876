import React from "react";
import {
  WppButton,
  WppCard,
  WppTopbar,
  WppTypography,
} from "@platform-ui-kit/components-library-react";

import styles from "./questionnarie.module.css";
import { AppTopbar } from "../../components/Dashboard/AppTopbar";

import { useLocation, useNavigate } from "react-router-dom";
import { MetadataDiffGrid } from "../../components/DataProcessing/MetadataIngestion/MetadataDiffGrid";

const QuestionnaireComparePage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { differences, question1, question2 } = state;
  console.log(state);

  const gridTemplate = (
    label: string = "",
    gridData: any[],
    columnDefs: any[]
  ) => {
    return (
      <div>
        <WppTypography type="l-body" slot="header">
          {label}
        </WppTypography>
        {gridData?.length > 0 ? (
          <MetadataDiffGrid data={gridData} columns={columnDefs} />
        ) : (
          <div style={{ height: "100%", textAlign: "center" }}>
            <WppTypography style={{ color: "#4d4c4c" }}>No Data</WppTypography>
          </div>
        )}
      </div>
    );
  };

  const differenceSection = (title: string, rowData: any[]) => {
    const columnDef = [];

    if (rowData && rowData.length) {
      Object.keys(rowData[0]).forEach((value) => {
        let columnObject = {
          headerName: formatHeader(value),
          field: value,
          tooltipField: value,
          tooltipComponentParams: { color: "#ececec" },
        };
        columnDef.push(columnObject);
      });
    }
    console.log(rowData, columnDef);
    return (
      <WppCard interactive className={styles.differenceCard}>
        <div style={{ minHeight: "150px", marginTop: "1rem" }}>
          {gridTemplate(title, rowData, columnDef)}
        </div>
      </WppCard>
    );
  };

  const formatHeader = (key) => {
    return key.replace(/_/g, " ").replace(/(?: |\b)(\w)/g, function (key) {
      return key.toUpperCase();
    });
  };

  return (
    <>
      <AppTopbar></AppTopbar>
      <WppTopbar
        navigation={[]}
        style={{ marginTop: "4rem", marginBottom: "1rem", width: "100%" }}
      >
        <div
          slot="app"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <WppTypography type="l-midi" style={{ width: "20rem" }}>
            Questions Comparison
          </WppTypography>
        </div>
      </WppTopbar>
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <WppButton
              className="wpp-spacing-48-right"
              onClick={() => navigate(-1)}
            >
              Go Back
            </WppButton>
          </div>
          <div>
            {question1 && question2 ? (
              <div style={{ margin: "2rem" }}>
                <div style={{ marginBottom: "0.5rem" }}>
                  <WppTypography type="l-midi">First Question:</WppTypography>{" "}
                  <WppTypography type="l-body" className={styles.highlightText}>
                    {question1}
                  </WppTypography>
                </div>
                <div>
                  <WppTypography type="l-midi">Second Question:</WppTypography>{" "}
                  <WppTypography type="l-body" className={styles.highlightText}>
                    {question2}
                  </WppTypography>
                </div>
              </div>
            ) : null}

            <div style={{ margin: "2rem" }}>
              <WppTypography type="l-midi" slot="header">
                Questions
              </WppTypography>
              <div>
                {differenceSection("Added", differences?.questions.added)}
                {differenceSection("Modified", differences?.questions.modified)}
                {differenceSection("Deleted", differences?.questions.deleted)}
              </div>
            </div>

            <div style={{ margin: "2rem" }}>
              <WppTypography type="l-midi" slot="header">
                Answers
              </WppTypography>
              <div>
                {differenceSection("Added", differences?.answers.added)}
                {differenceSection("Modified", differences?.answers.modified)}
                {differenceSection("Deleted", differences?.answers.deleted)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireComparePage;
