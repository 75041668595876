import React from "react";

import {AppTopbar} from "../../components/Dashboard/AppTopbar";
import {WppTypography} from "@platform-ui-kit/components-library-react";


const QuestionnaireHomeHiddenPage = () => {


    return (
        <div>
            <AppTopbar></AppTopbar>

            <div  style={{
                marginTop: "6rem",
                marginLeft: "4rem"
            }}>
                <WppTypography
                    type="2xl-heading"
                >
                    Coming Soon...
                </WppTypography>
            </div>
        </div>
    );
};
export default QuestionnaireHomeHiddenPage;
