import { createSlice } from "@reduxjs/toolkit";
import {QuestionsMetaDnd} from "../types";

interface QuestionMetadata {
  type: string;
  uuid: string;
  index: number;
  title: string;
}
export const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState: {
    id: null,
    version: null,
    previewerValues: null,
    builderValues: null,
    questionsMetadata: [] as QuestionsMetaDnd[],
  },
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    setQuestionVersion: (state, action) => {
      state.version = action.payload;
    },
    setPreviewerValues: (state, action) => {
      state.previewerValues = action.payload;
    },
    setBuilderValues: (state, action) => {
      state.builderValues = action.payload;
    },
    setQuestionsMetadata: (state, action) => {
      state.questionsMetadata = action.payload;
    },
  },
});

export const {
  setPreviewerValues,
  setBuilderValues,
  setQuestionsMetadata,
  setId,
  setQuestionVersion,
} = questionnaireSlice.actions;

export const selectBuilderValues = (state) => state.questionnaire.builderValues;
export const selectPreviewerValues = (state) =>
  state.questionnaire.previewerValues;
export const selectQuestionsMetadata = (state) =>
  state.questionnaire.questionsMetadata;
export const selectId = (state) => state.questionnaire.id;
export const selectVersion = (state) => state.questionnaire.version;

export default questionnaireSlice.reducer;
