import React, { Component } from "react";
import clsx from "clsx";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";

import AllureDockerMobileMenu from "../../components/AllureDockerMobileMenu/AllureDockerMobileMenu";
// import AllureDockerToolbar from "../../components/AllureDockerToolbar/AllureDockerToolbar";
import AllureDockerProjectsSideDrawer from "../../components/AllureDockerProjectsSideDrawer/AllureDockerProjectsSideDrawer";

const drawerWidth = 240;
const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

const DivRoot = styled("div")(({ theme }) => ({
  display: "flex",
}));

const DivDrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const MainContent = styled("main")(({ theme }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: -drawerWidth,
}));

class AllureDockerBar extends Component {
  state = {
    mobileMoreAnchorEl: null,
    openSideDrawer: false,
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleSideDrawerOpen = () => {
    this.setState({ openSideDrawer: true });
  };

  handleSideDrawerClose = () => {
    this.setState({ openSideDrawer: false });
  };

  render() {
    const isMobileMenuOpen = Boolean(this.state.mobileMoreAnchorEl);

    return (
      <DivRoot>
        <CssBaseline />
        {/* <AppBar position="fixed">
          <AllureDockerToolbar
            title="AO QA report UI"
            isSideDrawerOpen={this.state.openSideDrawer}
            handleSideDrawerOpen={this.handleSideDrawerOpen}
            handleSideDrawerClose={this.handleSideDrawerClose}
            handleMobileMenuOpen={this.handleMobileMenuOpen}
            darkState={this.props.darkState}
            handleThemeChange={this.props.handleThemeChange}
            getProjects={this.props.getProjects}
            setAPIAlert={this.props.setAPIAlert}
            isLogoutNeeded={this.props.isLogoutNeeded}
            isSignInAnOption={this.props.isSignInAnOption}
          />
        </AppBar> */}

        <AllureDockerMobileMenu
          isMobileMenuOpen={isMobileMenuOpen}
          anchorEl={this.state.mobileMoreAnchorEl}
          darkState={this.props.darkState}
          handleThemeChange={this.props.handleThemeChange}
          handleMobileMenuClose={this.handleMobileMenuClose}
        />

        <AllureDockerProjectsSideDrawer
          title="Allure Docker Service UI"
          projects={this.props.projects}
          isSideDrawerOpen={this.state.openSideDrawer}
          handleSideDrawerClose={this.handleSideDrawerClose}
          selectProject={this.props.selectProject}
        />

        <MainContent>{this.props.children}</MainContent>
      </DivRoot>
    );
  }
}

export default AllureDockerBar;
