export const formattedDate = (date: string) => {
    if (!date) {
        return "";
    }

    const dateObj = new Date(date);
    return `${(dateObj.getMonth() + 1).toString().padStart(2, "0")}-${dateObj
        .getDate()
        .toString()
        .padStart(2, "0")}-${dateObj.getFullYear()}`;
};

export const formatLabel = (label: string) => {
    return label
        .split("_")
        .map((val) => {
            if (typeof val === "string" && val) {
                return val[0].toUpperCase() + val.slice(1).toLowerCase();
            }

            return "";
        })
        .join(" ");
}