import { useDrag } from "react-dnd";
import {
  WppIconCopy,
  WppNavSidebarItem,
} from "@platform-ui-kit/components-library-react";
import React from "react";

export const DraggableQuestionIcon = ({ type }: { type: string }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "ICON",
    item: { type, itemType: "ICON" },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <WppNavSidebarItem label={type}>
        <WppIconCopy slot="icon-start" />
      </WppNavSidebarItem>
    </div>
  );
};
